import axios, { AxiosResponse } from 'axios';
import * as Yup from 'yup'
import { FC, useState, useEffect } from 'react'
import { DisplayLoader } from '../../utils/loader';
import { type } from 'os';
import { Field, Form, Formik, FormikValues } from 'formik';
import { ErrorDisplay } from '../../modules/components/common/ErrorDisplay';
import { Modal } from 'react-bootstrap';
import { jsPDF } from "jspdf";
import { useAuth } from '../../modules/auth';
import { PrintSection } from '../../modules/components/display/PrintSection';
import { challengeTypeToPresentTypeMap } from '../../models/Challenge';
import { generateOMRSheet } from '../../utils/generateOMR';
type Props = { id: string, challenge: any, sections: any, presentStateIn: number };
const Present: FC<Props> = ({ id, challenge, sections, presentStateIn }) => {
  const { currentUser, logout } = useAuth()
  let [presentations, setPresentations] = useState<any>([]);
  let [batches, setBatches] = useState<any>([]);
  let [currentChallengeInstance, setCurrentChallengeInstance] = useState<any>({});
  let [copyStatus, setCopyStatus] = useState<any>({});
  let [isOffline, setIsOffline] = useState<boolean>(false);
  let [showModal, setShowModal] = useState<boolean>(presentStateIn !== 0);
  let [presentState, setPresentState] = useState<number>(presentStateIn); //0-start,1-assignement,2-test,3-live
  let [error, setError] = useState<string>('');
  let challengeTypeToPresentStateMap: any = { 'assignment': 1, 'test': 2, 'print': 4 }
  let validationSchemaAssignment = Yup.object({
    batchId: Yup.string().required().label('Batch'),
    startDate: Yup.date().required().label('Start Date'),
    endDate: Yup.date().required().label('End Date'),
    startTimeH: Yup.number().lessThan(13).moreThan(0).required().label('Hour should be a number between 01 and 12'),
    startTimeM: Yup.number().lessThan(60).moreThan(-1).required().label('Minute should be a number between 00 and 59'),
    endTimeH: Yup.number().lessThan(13).moreThan(0).required().label('Hour should be a number between 01 and 12'),
    endTimeM: Yup.number().lessThan(60).moreThan(-1).required().label('Minute should be a number between 00 and 59'),
  })
  let validationSchemaOffline = Yup.object({
    batchId: Yup.string().required().label('Batch'),
    endDate: Yup.date().required().label('End Date'),
  })
  function addWaterMark(doc: any) {
    var totalPages = doc.internal.getNumberOfPages();

    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      //doc.addImage(imgData, 'PNG', 40, 40, 75, 75);
      doc.setTextColor(0, 0, 0, 0.3);
      doc.text(doc.internal.pageSize.width / 2 - 20, doc.internal.pageSize.height * 2 / 3, 'Olearno');
      doc.text(doc.internal.pageSize.width / 2 - 20, doc.internal.pageSize.height / 3, 'Olearno');
    }

    return doc;
  }
  const copyLink = (id: string, type: string) => {
    let newCopyStatus = { ...copyStatus }
    navigator.clipboard.writeText(window.location.origin + '/' + type + '/' + id);
    newCopyStatus[id] = true;
    setCopyStatus(newCopyStatus)
  }
  const print = () => {
    setShowModal(false)
    DisplayLoader(true, '')
    var doc = new jsPDF('p', 'pt', 'a4');
    doc.setProperties({
      title: challenge?.heading
    })
    doc.html((document.getElementById('print') || ''), {
      callback: function (doc) {
        let newDoc = addWaterMark(doc)
        newDoc.save(challenge?.heading + '.pdf');
        DisplayLoader(false, '')
      },
      margin: [20, 20, 20, 20],
      autoPaging: 'text',
      windowWidth: 700,
      width: 700,
      html2canvas: {
        scale: 0.8 // default is window.devicePixelRatio,
      },
      //width: 190, //target width in the PDF document
      //windowWidth: 650 //window width in CSS pixels
    });
  }
  function PrintWord() {
    setShowModal(false)
    DisplayLoader(true, '')
    let filename = challenge?.heading;
    var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    var postHtml = "</body></html>";
    var html = preHtml + (document.getElementById('print') as HTMLElement).innerHTML + postHtml;
    var blob = new Blob(['\ufeff', html], {
      type: 'application/msword'
    });
    var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
    filename = filename ? filename + '.doc' : 'document.doc';
    var downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    downloadLink.href = url;
    downloadLink.download = filename;
    downloadLink.click();
    document.body.removeChild(downloadLink);
    DisplayLoader(false, '')
  }
  const setCurrentChallengeInstanceMethod = (inputChallengeInstance: any) => {
    let currentInstance: any = inputChallengeInstance;
    let startDate = new Date(inputChallengeInstance.start)
    let endDate = new Date(inputChallengeInstance.end)
    currentInstance.startDate = startDate.getFullYear() + '-' + ((startDate.getMonth() + 1 < 10) ? ('0' + (startDate.getMonth() + 1)) : (startDate.getMonth() + 1)) + '-' + ((startDate.getDate() < 10) ? ('0' + (startDate.getDate())) : (startDate.getDate()));
    currentInstance.startTimeH = startDate.getHours() === 0 ? 12 : startDate.getHours();
    currentInstance.startTimeM = startDate.getMinutes() < 10 ? ('0' + startDate.getMinutes()) : startDate.getMinutes();
    if (currentInstance.startTimeH > 12) {
      currentInstance.startTimeH = currentInstance.startTimeH - 12;
    }
    if (startDate.getHours() >= 12) {
      currentInstance.startTimeAMPM = 'pm';
    }
    currentInstance.startTimeH = currentInstance.startTimeH < 10 ? ('0' + currentInstance.startTimeH) : currentInstance.startTimeH
    currentInstance.endDate = endDate.getFullYear() + '-' + ((endDate.getMonth() + 1 < 10) ? ('0' + (endDate.getMonth() + 1)) : (endDate.getMonth() + 1)) + '-' + ((endDate.getDate() < 10) ? ('0' + (endDate.getDate())) : (endDate.getDate()));
    currentInstance.endTimeH = endDate.getHours() === 0 ? 12 : endDate.getHours();
    if (currentInstance.endTimeH > 12) {
      currentInstance.endTimeH = currentInstance.endTimeH - 12;
    }
    if (endDate.getHours() >= 12) {
      currentInstance.endTimeAMPM = 'pm';
    }
    currentInstance.endTimeH = currentInstance.endTimeH < 10 ? ('0' + currentInstance.endTimeH) : currentInstance.endTimeH
    currentInstance.endTimeM = endDate.getMinutes() < 10 ? ('0' + endDate.getMinutes()) : endDate.getMinutes();
    setCurrentChallengeInstance(currentInstance);
  }
  const listBatches = () => {
    if (batches.length > 0) return
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/batch-list/').then((reply) => {
      if (reply?.data) {
        setBatches(reply.data);
        DisplayLoader(false, '')
      } else {
        //TODO
      }
    })
  }
  const createAssignmentInstance = (values: FormikValues) => {
    console.log("here")
    if (!(presentState === 1 || presentState === 2 || presentState === 4)) return
    if(isOffline || presentState == 4) {
      values.end = new Date(values.endDate)
    } else {
      values.startTimeH = values.startTimeH === 12 ? 0 : values.startTimeH;
      values.endTimeH = values.endTimeH === 12 ? 0 : values.endTimeH;
      values.start = new Date(new Date(values.startDate).getTime() + (parseInt(values.startTimeH) + (values.startTimeAMPM === 'pm' ? 12 : 0)) * 60 * 60 * 1000 + (parseInt(values.startTimeM) + new Date().getTimezoneOffset()) * 60 * 1000);
      values.end = new Date(new Date(values.endDate).getTime() + (parseInt(values.endTimeH) + (values.endTimeAMPM === 'pm' ? 12 : 0)) * 60 * 60 * 1000 + (parseInt(values.endTimeM) + new Date().getTimezoneOffset()) * 60 * 1000);
      console.log("here")
      if (new Date(values.end) < new Date(values.start)) {
        setError('End Time should be after Start Time')
        return
      }
      if (presentState === 2 && !values['totalTime']) {
        setError('Total Time is required')
        return
      }
    }
    createChallengeInstance(values, (presentState === 1 ? 'assignment' : 'test'));
  }
  const createChallengeInstance = (values: FormikValues, type: string) => {
    DisplayLoader(true, '')
    setError('')
    axios.post(process.env.REACT_APP_API_URL + '/create-challenge-instance/', { ...{ challengeId: id, type: type }, ...values }).then((reply) => {
      if (reply?.data?.challengeInstanceId) {
        if (type === 'lesson') {
          window.location.pathname = '/show-lesson/' + reply.data.challengeInstanceId;
        } else {
          listPresentations();
          setPresentState(0);
          //window.location.pathname = '/show-challenge/' + reply.data.challengeInstanceId;
        }
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }
  const listPresentations = () => {
    axios.get(process.env.REACT_APP_API_URL + '/get-challenge-instance-list/' + id).then((reply) => {
      if (reply?.data?.challengeInstances) {
        setIsOffline(challenge.presentationMode == 'offline')
        setPresentations(reply.data.challengeInstances);
        setPresentState((challenge.presentationMode && !(reply.data.challengeInstances && reply.data.challengeInstances.length > 0)) ? challengeTypeToPresentStateMap[challengeTypeToPresentTypeMap[challenge.presentationMode][challenge.type]] : 0)
      } else {
        //TODO
      }
    })
  };
  const createOMRConfig = () => {
    let OMRConfig : any = {}
    let subjectList = ['physics','chemistry','maths','biology']
    subjectList.forEach((subject : string)=>{
      let count = challenge.sections.filter((section : any)=>{
        return sections[section].tags.indexOf(subject) != -1
      }).length;
      if(count > 0) {
        OMRConfig[subject] = count;
      }
    })
    return OMRConfig;
  }
  return (
    <>
      <button type='button' onClick={() => { listPresentations(); listBatches(); setError(''); setCurrentChallengeInstance({}); setShowModal(true) }} className='btn btn-info m-1 float-end p-2 pt-1 pb-1 shadow-lg'>
        <i className="fa fa-tv"></i><small>Present</small>
      </button>
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showModal} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowModal(false) }}>
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title>Present</Modal.Title>
          </Modal.Header>
          <div className="modal-body">
            <h3 className='text-center'>{challenge.heading} - {presentState === 1 ? 'Assignment' : 'Test'}</h3>
            <hr />
            {presentations && presentations.length > 0 && (presentState === 0) && <div className='text-center'>
              <h4>Previous Presentations</h4>
              <div className='shadow p-1 mt-3'>
                <div className='col-10 d-inline-block text-left'>
                  <strong>
                    <div className='col-4 d-inline-block text-center'>Type</div>
                    <div className='col-4 d-inline-block text-center'>Batch</div>
                    <div className='col-4 d-inline-block text-center'>{isOffline ? 'Date' : 'End Time'}</div>
                  </strong>
                </div>
                <div className='col-1 d-inline-block'></div>
                {
                  presentations.map((presentation: any) => {
                    return <div>{
                      <div>
                        <div className='card p-3 m-2 col-10 d-inline-block border-bottom'>
                          <div className='col-4 d-inline-block'>
                            {presentation.type.slice(0, 1).toUpperCase() + presentation.type.slice(1)}
                          </div>
                          <div className='col-4 d-inline-block'>
                            {batches.filter((batch: any) => { return batch._id === presentation.batchId })[0]?.name}
                            {!presentation.batchId && !isOffline && <button className='btn btn-sm btn-dark' onClick={() => { copyLink(presentation._id, presentation.type) }}>{copyStatus[presentation._id] ? 'Link Copied' : 'Copy Link'}</button>}
                            {!presentation.batchId && isOffline && <>Anyone</>}
                          </div>
                          <div className='col-4 d-inline-block'>
                            {presentation.end && !isOffline && new Date(presentation.end).toLocaleString()}
                            {presentation.end && isOffline && new Date(presentation.end).toLocaleDateString()}
                          </div>
                        </div>
                        <button className='btn btn-dark col-1 d-inline-block p-1' onClick={() => { setCurrentChallengeInstanceMethod(presentation); setPresentState(challenge.presentationMode ? challengeTypeToPresentStateMap[challengeTypeToPresentTypeMap[challenge.presentationMode][challenge.type]] : 1); setError('') }}><i className="fa fa-pencil"></i></button>
                      </div>
                    }</div>
                  })
                }
              </div>
            </div>}
            {presentState === 0 && <>
              <div className='text-center mt-3'>
                {challenge && challenge.presentationMode && challengeTypeToPresentTypeMap[challenge.presentationMode][challenge.type].map((type: string) => {
                  if (type === 'print') {
                    return <button type='button' onClick={() => { setPresentState(4) }} className='btn btn-info m-1 col-3 p-2 shadow-lg'>
                      <i className="fa fa-print"></i><br /><small>Print</small>
                    </button>
                  }
                  if (type === 'test') {
                    return <button type='button' onClick={() => { setPresentState(2) }} className='btn btn-info m-1 col-3 p-2 shadow-lg'>
                      <i className="fa fa-clock"></i><br /><small>New Test</small>
                    </button>
                  }
                  if (type === 'assignment') {
                    return <button type='button' onClick={() => { setPresentState(1) }} className='btn btn-info m-1 col-3 p-2 shadow-lg'>
                      <i className="fa fa-pencil"></i><br /><small>New Assignment</small>
                    </button>
                  }
                })}
                {challenge && !challenge.presentationMode && <>
                  <button type='button' onClick={() => { setPresentState(4) }} className='btn btn-info m-1 col-3 p-2 shadow-lg'>
                    <i className="fa fa-print"></i><br /><small>Print</small>
                  </button>
                  <button type='button' onClick={() => { setPresentState(2) }} className='btn btn-info m-1 col-3 p-2 shadow-lg'>
                    <i className="fa fa-clock"></i><br /><small>Test</small>
                  </button>
                  <button type='button' onClick={() => { setPresentState(1) }} className='btn btn-info m-1 col-3 p-2 shadow-lg'>
                    <i className="fa fa-pencil"></i><br /><small>Assignment</small>
                  </button>
                </>
                }
                {/*<button type='button' onClick={() => { setPresentState(3) }} className='btn btn-info m-1 col-3 p-2 shadow-lg'>
                  <i className="fa fa-headset"></i><br /><small>Live</small>
            </button>*/}
              </div>
              <hr />
            </>}
            {currentUser?.isNotLoggedIn && presentState !== 0 && <div className='text-center'>
              <h4>Access this feature by Logging In or Registering for free</h4>
              <button className='btn btn-info pb-1 pt-1' onClick={() => { logout() }}>Log In<br />
                <span style={{ fontSize: "8px" }}>or Register</span>
              </button>
            </div>}
            {!currentUser?.isNotLoggedIn && !isOffline && (presentState === 1 || presentState === 2) && <div className='text-start'>
              <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
                validationSchema={validationSchemaAssignment} initialValues={currentChallengeInstance} onSubmit={createAssignmentInstance}>
                {({ errors, touched, values, setFieldValue, setErrors }) => (
                  <Form>
                    <div><label className='form-label fw-bolder text-dark fs-6'>Batch</label></div>
                    <div>
                      <Field as="select" name="batchId" className="form-select mb-1">
                        <option value=''>--Select--</option>
                        <option value='anylink'>Anybody with a Link</option>
                        {batches.map((batch: any) => <option value={batch._id}>{batch.name}</option>)}
                      </Field>
                      {ErrorDisplay('batchId', errors)}
                      <div><label className='form-label fw-bolder text-dark fs-6'>Start Date</label></div>
                      <Field type='date' className='form-control form-control-lg mb-1'
                        name='startDate' placeholder='Title' />
                      {ErrorDisplay('startDate', errors)}
                      <div><label className='form-label fw-bolder text-dark fs-6'>Start Time</label></div>
                      <div>
                        <div className='col-3 col-md-2 d-inline-block me-2'>
                          <Field type='text' className='form-control form-control-xs mb-1'
                            name='startTimeH' placeholder='hh' number />
                        </div>
                        <div className='col-3 col-md-2 d-inline-block'>
                          <Field type='text' className='form-control form-control-xs mb-1'
                            name='startTimeM' placeholder='mm' number />
                        </div>
                        <div className='col-3 col-md-2 d-inline-block'>
                          <Field as="select" name="startTimeAMPM" className="form-select mb-1">
                            <option value='am'>AM</option>
                            <option value='pm'>PM</option>
                          </Field>
                        </div>
                        {ErrorDisplay('startTimeH', errors)}
                        {ErrorDisplay('startTimeM', errors)}
                      </div>
                      <div><label className='form-label fw-bolder text-dark fs-6'>End Date</label></div>
                      <Field type='date' className='form-control form-control-lg mb-1'
                        name='endDate' placeholder='Title' />
                      {ErrorDisplay('endDate', errors)}
                      <div><label className='form-label fw-bolder text-dark fs-6'>End Time</label></div>
                      <div>
                        <div className='col-3 col-md-2 d-inline-block me-2'>
                          <Field type='text' className='form-control form-control-xs mb-1'
                            name='endTimeH' placeholder='hh' number />
                        </div>
                        <div className='col-3 col-md-2 d-inline-block'>
                          <Field type='text' className='form-control form-control-xs mb-1'
                            name='endTimeM' placeholder='mm' number />
                        </div>
                        <div className='col-3 col-md-2 d-inline-block'>
                          <Field as="select" name="endTimeAMPM" className="form-select mb-1">
                            <option value='am'>AM</option>
                            <option value='pm'>PM</option>
                          </Field>
                        </div>
                        {ErrorDisplay('endTimeH', errors)}
                        {ErrorDisplay('endTimeM', errors)}
                      </div>
                      {presentState === 2 && <>
                        <div><label className='form-label fw-bolder text-dark fs-6'>Total Time (in mins)</label></div>
                        <Field type='text' className='form-control form-control-lg mb-1'
                          name='totalTime' placeholder='Total Time' />
                        {ErrorDisplay('totalTime', errors)}
                      </>}
                    </div>
                    {error && error !== '' && <div className='text-danger'>*{error}</div>}
                    <button type='button' className='btn btn-dark' onClick={() => { listPresentations(); listBatches(); setShowModal(true); setError(''); setCurrentChallengeInstance({}); }}>Back</button>
                    <button className='btn btn-primary float-end'>Submit</button>
                  </Form>)}
              </Formik>

            </div>}
            {!currentUser?.isNotLoggedIn && ((isOffline && presentState != 0) || presentState === 4) && <div className='text-start'>
              <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
                validationSchema={validationSchemaOffline} initialValues={currentChallengeInstance} onSubmit={createAssignmentInstance}>
                {({ errors, touched, values, setFieldValue, setErrors }) => (
                  <Form>
                    <div><label className='form-label fw-bolder text-dark fs-6'>Batch</label></div>
                    <div>
                      <Field as="select" name="batchId" className="form-select mb-1">
                        <option value=''>--Select--</option>
                        <option value='anylink'>Anybody with a Link</option>
                        {batches.map((batch: any) => <option value={batch._id}>{batch.name}</option>)}
                      </Field>
                      {ErrorDisplay('batchId', errors)}
                      <div><label className='form-label fw-bolder text-dark fs-6'>Date</label></div>
                      <Field type='date' className='form-control form-control-lg mb-1'
                        name='endDate' placeholder='Title' />
                      {ErrorDisplay('endDate', errors)}
                    </div>
                    {error && error !== '' && <div className='text-danger'>*{error}</div>}
                    <button type='button' className='btn btn-dark' onClick={() => { listPresentations(); listBatches(); setShowModal(true); setError(''); setCurrentChallengeInstance({}); }}>Back</button>
                    <button className='btn btn-primary float-end'>Submit</button>
                  </Form>)}
              </Formik>

            </div>}
            {/*!currentUser?.isNotLoggedIn && isOffline && <>
              <div className='text-center'>
                <h4>Download</h4>
                <button type='button' className='btn btn-info p-2 me-4' onClick={() => { PrintWord() }}>Word (.doc)</button>
                <button type='button' className='btn btn-info p-2 me-4' onClick={() => { print() }}>PDF</button>
                <button type='button' className='btn btn-info p-2' onClick={() => { generateOMRSheet('Olearno','JEE Mains Prep 1','https:\/\/fastly.picsum.photos/id/48/200/200.jpg?hmac=3FKJwSlm1FM1GD916vZX2Z3HUjHsUXvQM3rYWYXsQvc',6,createOMRConfig(),false) }}>OMR</button>
                <div style={{ fontSize: "12px" }}>*.doc files can't be opened in a phone</div>
              </div>
              <div id="print" style={{ width: '100%' }}>
                {challenge?.sections?.map((section: any, index: number) => {
                  return <div className='mb-4'>
                    <h4>Question No. {index + 1}</h4>
                    
                    {PrintSection(sections[section])}
                    <hr></hr>
                  </div>
                })}
              </div>
              </>*/}
            {!currentUser?.isNotLoggedIn && presentState === 3 && <>

            </>}
          </div>
        </div>
      </Modal>
    </>
  )
}

export { Present }
