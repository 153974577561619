import axios from 'axios';
import { FC, useState, useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';
import DatePicker, { registerLocale } from "react-datepicker";
import Select from 'react-select'
import "react-datepicker/dist/react-datepicker.css";
import { enIN } from 'date-fns/locale/en-IN';
import { DisplayLoader } from '../../utils/loader';
import { Modal } from 'react-bootstrap';
type ApiParams = { startDate: string, endDate: string, batch: string };
const OfflineReports: FC = () => {
  const location = useLocation();
  let [report, setReport] = useState<any>({});
  let [showModal, setShowModal] = useState<boolean>(false);
  const [filterStartDate, setFilterStartDate] = useState<any>();
  const [filterEndDate, setFilterEndDate] = useState<any>();
  const [filterBatch, setFilterBatch] = useState<any>();

  const queryParams = new URLSearchParams(location.search);

  const startDate = queryParams.get('startDate');
  const endDate = queryParams.get('endDate');
  const batch = queryParams.get('batch');

  registerLocale('enIN', enIN)
  useEffect(() => {
    getReports();
  }, []);
  useEffect(() => {
    getReports()
    setShowModal(false);
  }, [startDate, endDate, batch]);
  const resetFilters = () => {
    setFilterStartDate('')
    setFilterEndDate('')
    setFilterBatch(null)
  }
  const getReports = () => {
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/get-offline-reports?startDate=' + startDate + '&endDate=' + endDate + '&batch=' + batch).then((reply) => {
      if (reply?.data) {
        if (reply.data.redirect) {
          window.location.pathname = reply.data.redirect;
        } else {
          setReport(reply.data);
        }
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }
  return (
    <div className='rounded border border-dark p-2 pb-0 shadow-lg m-5'>
      <h2 className='text-center' style={{position: 'relative'}}>Class Reports
        <button className='btn btn-dark p-2' style={{position: 'absolute', right: '50px'}} onClick={() => { setShowModal(true) }}><i className='fa fa-filter pe-0'></i></button>
        <Link to={'/class-reports-summary'} style={{position: 'absolute', right: 0}} className='btn btn-dark p-2 me-2'><i className='fa fa-list-alt pe-0'></i></Link>
      </h2>
      <div className='text-center'>
        {batch && batch.length > 9 && <b>{report.batchList.filter((b: any) => b._id == batch)[0]?.name}</b>}
        {batch && batch.length > 9 && (!isNaN(parseInt(startDate || '')) || !isNaN(parseInt(endDate || ''))) && <> - </>}
        {(!isNaN(parseInt(startDate || '')) || !isNaN(parseInt(endDate || ''))) &&
          <b className='ms-4'>
            {(startDate && !isNaN(parseInt(startDate || ''))) ? new Date(parseInt(startDate)).toLocaleDateString('en-IN') + ' - ' : 'until '}
            {(endDate && !isNaN(parseInt(endDate || ''))) ? new Date(parseInt(endDate)).toLocaleDateString('en-IN') : 'Now'}
          </b>
        }
      </div>
      {report?.sessions?.length == 0 && <div className='text-center m-5'>
        <b>Nothing to show</b>
      </div>}
      {report?.sessions?.map((session: any) => {
        return <div className='shadow-lg p-3 m-2 pb-15 mt-4 rounded'>
          <div style={{ fontSize: '16px' }}>
            For <strong>{report.batchList.filter((batch: any) => batch._id == session.batchId)[0]?.name}</strong> by <strong>{report.userDetails[session.teacherId]}</strong> <strong className='float-end'>{new Date(session.startTime).toLocaleDateString('en-GB')}</strong>
          </div>
          <div>
            <strong>Topic/s Taught:</strong>
            {
              Array.from(new Set(session.showOrder.map((order: any) => order.challengeInstanceId))).map((order: any) => {
                return <div>
                  {(() => {
                    const item = report.challengeInstanceDependencyMap.filter((c: any) => c.id == order)[0]
                    if (!item) return order
                    return item.dependency.join('->') + '->' + item.name
                  })()
                  }
                </div>
              })
            }
          </div>
          <div>
            {/* <a href={'/class-reports-group/' + session._id} type='button' className='btn btn-dark p-2 m-2'><i className='fa fa-line-chart'></i> by Group/Student</a> */}
            <Link to={'/class-reports-detailed/' + session._id} type='button' className='btn btn-dark p-2 m-2 float-end'><i className='fa fa-line-chart'></i></Link>
          </div>
        </div>
      })}
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showModal} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowModal(false) }}>
        <div className="modal-content">
          <Modal.Header closeButton>
            <Modal.Title>Filter by Dates</Modal.Title>
          </Modal.Header>
          <div className="modal-body">
            {report.batchList && <div className='p-3 pb-4 shadow rounded'>
              <div className='m-2 row'>
                <div className='col-6'><strong>Batch</strong></div>
                <div className='col-6 '>
                  <Select className='ms-4' value={filterBatch} name='batch' id='batch' options={report.batchList.map((batch: any) => { return { value: batch._id, label: batch.name } })} onChange={(value) => { setFilterBatch(value) }} />
                </div>
              </div>
              <div className='m-2 row'>
                <div className='col-6'><strong>From</strong></div>
                <div className='col-6 '><DatePicker locale={'enIN'} selected={filterStartDate} onChange={(date) => setFilterStartDate(date)} placeholderText='from' className='ms-4' /></div>
              </div>
              <div className='m-2 row'>
                <div className='col-6 '><strong>To</strong></div>
                <div className='col-6 '><DatePicker locale={'enIN'} selected={filterEndDate} onChange={(date) => setFilterEndDate(date)} placeholderText='to' className='ms-4' /></div>
              </div>
              <div >
                <button className='btn btn-dark p-2' onClick={resetFilters}>Reset Filters</button>
                <Link to={'/class-reports?startDate=' + new Date(filterStartDate).getTime() + '&endDate=' + new Date(filterEndDate).getTime() + '&batch=' + filterBatch?.value} className='p-2 btn btn-dark float-end'>Submit</Link>
              </div>
            </div>}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export { OfflineReports }
