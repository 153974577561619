import React, { useEffect, useRef, useState } from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { TextEditor } from '../common/TextEditor';
import { DisplayLoader } from '../../../utils/loader';
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import Latex from 'react-latex-next';
const firebaseConfig = {
  apiKey: "AIzaSyByPMDxjiibLupiXvlbubCSlsabPFurpXA",
  authDomain: "olearno-4b1fb.firebaseapp.com",
  databaseURL: "https://olearno-4b1fb-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "olearno-4b1fb",
  storageBucket: "olearno-4b1fb.appspot.com",
  messagingSenderId: "990749151543",
  appId: "1:990749151543:web:8652bce69adc94c82acab2",
  measurementId: "G-PY2RTFHBE3"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

interface SimulationGraphProps {
  section: any;
  updateText?: Function;
}
const SimulationGraph: React.FC<SimulationGraphProps> = ({ section, updateText }) => {
  const [text, setText] = useState('');
  const [simulation, setSimulation] = useState('');
  useEffect(() => {
    if (section.text) {
      setText(section.text.split(':simulation:')[0])
      setSimulation(section.text.split(':simulation:')[1])
    }
  }, [])
  useEffect(() => {
    if (simulation == '') return
    DisplayLoader(true, '');
    const script = document.createElement('script');
    script.src = toAbsoluteUrl('/js/deployggb.js'); // Adjust the path as necessary
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      var applet2 = new GGBApplet({
        filename: simulation, appletOnLoad: function (api:any) {
          DisplayLoader(false, '')
          console.log('GeoGebra applet has loaded successfully.');
        }
      }, true);
      applet2.inject('ggb-element');
      //  when used with Math Apps Bundle, uncomment this:
      //  applet2.setHTML5Codebase('GeoGebra/HTML5/5.0/web/');
      /*
      window.onload = function () {
        applet2.inject('ggb-element');
      }
      */
    };
    return () => {
      document.body.removeChild(script);
    };
  }, [simulation]);
  const updateTextLocal = (dummy: number, textIn: string) => {
    if (!updateText) return
    setText(text);
    updateText(0, textIn + ':simulation:' + simulation);
  }
  const uploadSimulation = function (evt: any, section: any) {
    if (!updateText) return
    if (!evt.target.files[0]) return
    var file = evt.target.files[0];
    const storageRef = ref(storage, 'engage/sections/' + section._id + '/' + file.name);
    const uploadTask = uploadBytesResumable(storageRef, file);
    DisplayLoader(true, '')
    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          DisplayLoader(false, '')
          updateText(0, text + ':simulation:' + downloadURL)
          setSimulation(downloadURL);
          /*
          cb(downloadURL, {
            title: file.name
          });
          */
        });
      }
    );

  };
  return (
    <div>
      {updateText && <>
        <div><label className='form-label fw-bolder text-dark fs-6'>Question</label></div>
        <div>
          <input type='text' className='form-control form-control-lg mb-1' id='question' onChange={((evt) => { setText(evt.target.value); updateText(0, evt.target.value + ':simulation:' + simulation) })} />
        </div>
      </>}
      {!updateText && <b><Latex>{text || ''}</Latex></b>}
      <div id="ggb-element"></div>
      {updateText && <input type='file' className='btn btn-info' onChange={(e) => { uploadSimulation(e, section) }} />}
    </div>
  );
};

export default SimulationGraph;
