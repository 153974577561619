import React from 'react'
import * as Yup from 'yup'
import Select from 'react-select'
import axios, { AxiosResponse } from 'axios';
import { FC, useState, useEffect, ChangeEvent } from 'react'
import { Form, Formik, FormikValues, Field } from 'formik'
import { DisplayLoader } from '../../utils/loader'
import { Modal } from 'react-bootstrap';
import { specialTagsForStream, topicList, topicListForStream } from '../../models/LocalLists';
import { ErrorDisplay } from '../../modules/components/common/ErrorDisplay';
import { an } from '@fullcalendar/core/internal-common';
import { tempQuestionTypeList } from '../../models/Challenge';
import { toASCII } from 'punycode';

const GenerateChallenge: FC = () => {
  let [contentType, setContentType] = useState<string>('assignment');
  let [presentationMode, setPresentationMode] = useState<string>('online');
  let [difficulty, setDifficulty] = useState<string>('cet');
  let [topics, setTopics] = useState<string[]>(['']);
  let [questionTypes, setQuestionTypes] = useState<string[]>([]);
  let [questionConfigurationList, setQuestionConfigurationList] = useState<any>([]);
  let [errorDetails, setErrorDetails] = useState<any>({});
  let [showModal, setShowModal] = useState<boolean>(false);
  let [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  let [errors, setErrors] = useState<any>({});
  let validationSchema = Yup.object({
    questionTypeSelect: Yup.object().test("At least 1 topic required", () => {
      if (questionTypes.length < 1) {
        throw new Yup.ValidationError('At least 1 question type required', "value", 'questionTypes');
      }
      return true
    }),
    noOfQuestions: Yup.number().moreThan(0).required().label('This should be a number greater than zero'),
    topicList: Yup.mixed().test("At least 1 topic required", () => {
      if (topics.length < 1) {
        throw new Yup.ValidationError('At least 1 topic required', "value", 'topics');
      }
      return true
    })
  })
  const updateTopics = (value: string) => {
    if (!value || value == '') return
    let newTopics = [...topics]
    newTopics.push(value);
    setTopics(newTopics);
  }
  const updateQuestionTypes = (values: any) => {
    setQuestionTypes(values?.map((value: any) => { return value.value }))
  }
  const removeQuestionConfiguration = (index: number) => {
    let newQuestionConfigurationList = [...questionConfigurationList];
    newQuestionConfigurationList.splice(index, 1);
    setQuestionConfigurationList(newQuestionConfigurationList);
  }
  const addQuestionConfiguration = (values: FormikValues) => {
    let questionConfiguration = {
      noOfQuestions: values.noOfQuestions,
      difficulty: (document.getElementById('difficulty') as HTMLInputElement).value,
      questionTypes: questionTypes,
      topics: topics,
    }
    let newQuestionConfigurationList = [...questionConfigurationList];
    newQuestionConfigurationList.push(questionConfiguration);
    setQuestionConfigurationList(newQuestionConfigurationList);
    setShowModal(false);
  }
  const generateQuestionPaper = () => {
    let heading = (document.getElementById('heading') as HTMLInputElement).value
    setErrors({})
    if (!heading || heading === '') {
      setErrors({ heading: 'Required' })
      return
    }
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/question-paper-generator/',
      { heading: heading, contentType: contentType, presentationMode: presentationMode, config: questionConfigurationList, difficulty: difficulty })
      .then((reply) => {
        if (reply?.data?.challengeId) {
          window.location.href = '/create-module/' + reply.data.challengeId;
        } else if (reply?.data?.challengeInstanceId) {
          window.location.href = '/' + reply.data.type + '/' + reply.data.challengeInstanceId;
        } else if (reply?.data?.error && reply?.data?.actualQuestionConfigs) {
          setErrorDetails(reply?.data)
          setShowErrorModal(true);
          DisplayLoader(false, '')
        } else {
          setErrors({ 'status': 'Unfortunately we were unable to generate for this configuration' })
          DisplayLoader(false, '')
          //TODO
        }
      }, (error) => {
        //reject(error)//TODO
        setErrors({ 'status': 'Unfortunately we were unable to generate for this configuration' })
        DisplayLoader(false, '')
      });
  }
  return (
    <div className='rounded border border-dark p-2 pb-0 shadow-lg bg-light shadow'>
      {errors['status'] && errors['status'] !== '' && <div className='bg-danger text-white mb-3 rounded p-5 text-center'>{'*' + errors['status']}</div>}
      <div>
        <div className='col-3 m-2 ms-4 d-inline-block me-5'><label className='form-label fw-bolder text-dark fs-6'>Name</label></div>
        <div className='col-7 d-inline-block'><input type='text' id='heading' className='form-control form-control mb-1' /></div>
        {errors['heading'] && errors['heading'] !== '' && <div className='text-danger'>{'*' + errors['heading']}</div>}
      </div>
      <div>
        <div className='col-3 m-2 ms-4 d-inline-block me-5'><label className='form-label fw-bolder text-dark fs-6'>Mode of Presentation</label></div>
        <div className='col-7 d-inline-block'>
          <select className='form-select form-select-sm form-select-solid text-center fs-3 bg-white'
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              setPresentationMode(event.target.value);
            }}>
            <option value={'online'}>Online</option>
            <option value={'offline'}>Offline - Printouts</option>
          </select>
        </div>
      </div>
      <div>
        <div className='col-3 m-2 ms-4 d-inline-block me-5'><label className='form-label fw-bolder text-dark fs-6'>Content Type</label></div>
        <div className='col-7 d-inline-block'>
          <select className='form-select form-select-sm form-select-solid text-center fs-3 bg-white'
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              setContentType(event.target.value);
            }}>
            {/*<option value={'lesson'}>Lesson</option>*/}
            <option value={'assignment'}>Assignment</option>
            <option value={'test'}>Test</option>
          </select>
        </div>
      </div>
      <div>
        <div className='col-3 m-2 ms-4 d-inline-block me-5'><label className='form-label fw-bolder text-dark fs-6'>Target Test</label></div>
        <div className='col-7 d-inline-block'>
          <select name='difficulty' id='difficulty' className='form-select form-select-sm form-select-solid text-center fs-3 bg-white'
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              setDifficulty(event.target.value);
            }}>
            <option value={'cet'}>CET</option>
            <option value={'mains'}>JEE Mains</option>
            <option value={'advanced'}>JEE Advanced</option>
            <option value={'neet'}>NEET</option>
          </select>
        </div>
      </div>
      {
        questionConfigurationList?.map((config: any, index: number) => {
          return <div className='rounded m-4 p-4 bg-white'>
            <button className='float-end btn btn-danger p-2' onClick={() => { removeQuestionConfiguration(index) }}>Remove</button>
            <div>Topics - <b>{config.topics.join(', ')}</b></div>
            <div>Question Types - <b>{config.questionTypes.join(', ')}</b></div>
            <div>No. of Questions - <b>{config.noOfQuestions}</b></div>
          </div>
        })
      }
      {
        questionConfigurationList.length > 0 && <div className='ms-5'>
          <strong>Total Questions : {questionConfigurationList.reduce((a: number, c: any) => a + c.noOfQuestions, 0)}</strong>
        </div>
      }
      <div>
        <button className='btn btn-primary m-3' onClick={() => { setShowModal(true) }}>Add Question Configuration</button>
        {
          questionConfigurationList.length > 0 && <button type='button' className='btn btn-primary m-3 float-end' onClick={() => { generateQuestionPaper() }}>Generate</button>
        }
      </div>
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showErrorModal} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowErrorModal(false) }}>
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title>Configuration Miss</Modal.Title>
          </Modal.Header>
          <div className="modal-body">
            <div>
              <div>
                We regret to inform you that we couldn't generate a question paper for you. This might be due to the following reasons:
                <ol>
                  <li>The selected topic may not align with the requirements of the test you've chosen.</li>
                  <li>You've exhausted all available questions in the previous question papers you've generated.</li>
                </ol>
                Below is a list of topics where we faced challenges generating questions. To address this, you can either <b>reduce the number of questions</b> or <b>provide alternative topics.</b>
              </div>
              {errorDetails?.actualQuestionConfigs?.map((config: any, index: number) => {
                return config.noOfQuestions != errorDetails.requestedQuestionConfig[index]?.noOfQuestions && <div className='rounded m-4 p-4 bg-white shadow'>
                  <div>Topics - <b>{config.topics.join(', ')}</b></div>
                  <div>Question Types - <b>{config.questionTypes.join(', ')}</b></div>
                  <div>Available No. of questions <b className='text-danger'>{config.noOfQuestions}</b></div>
                  <div>Requested No. of questions <b className='text-danger'>{errorDetails.requestedQuestionConfig[index]?.noOfQuestions}</b></div>
                  <div>
                    <b>Topic Weightage</b>
                    <table className='table table-bordered'>
                      <thead>
                        <tr><td>Topic</td><td>Category</td><td>CET</td><td>NEET</td><td>Mains</td><td>Advanced</td></tr>
                      </thead>
                      <tbody>
                        {
                          config.topics.map((topic: string) => {
                            return <tr>
                              <td>{topic}</td><td>{topicListForStream['JEE-NEET'][topic]?.parent}</td>
                              <td>{Math.round((topicListForStream['JEE-NEET'][topic]?.['CET'] / (topicListForStream['JEE-NEET'][topicListForStream['JEE-NEET'][topic]?.parent].children.reduce((a: number, topic: any) => parseInt(topicListForStream['JEE-NEET'][topic]['CET']) + a, 0) || 1)) * 100)}%</td>
                              <td>{Math.round((topicListForStream['JEE-NEET'][topic]?.['NEET'] / (topicListForStream['JEE-NEET'][topicListForStream['JEE-NEET'][topic]?.parent].children.reduce((a: number, topic: any) => parseInt(topicListForStream['JEE-NEET'][topic]['NEET']) + a, 0) || 1)) * 100)}%</td>
                              <td>{Math.round((topicListForStream['JEE-NEET'][topic]?.['JEE-Mains'] / (topicListForStream['JEE-NEET'][topicListForStream['JEE-NEET'][topic]?.parent].children.reduce((a: number, topic: any) => parseInt(topicListForStream['JEE-NEET'][topic]['JEE-Mains']) + a, 0) || 1)) * 100)}%</td>
                              <td>{Math.round((topicListForStream['JEE-NEET'][topic]?.['JEE-Advanced'] / (topicListForStream['JEE-NEET'][topicListForStream['JEE-NEET'][topic]?.parent].children.reduce((a: number, topic: any) => parseInt(topicListForStream['JEE-NEET'][topic]['JEE-Advanced']) + a, 0) || 1)) * 100)}%</td>
                            </tr>
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              })}
              <div className='text-center'>
                <button className='btn btn-primary' onClick={() => { setShowErrorModal(false) }}>Close</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showModal} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowModal(false) }}>
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title>Question Configuration</Modal.Title>
          </Modal.Header>
          <div className="modal-body">
            <div>
              <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
                validationSchema={validationSchema} initialValues={{}} onSubmit={addQuestionConfiguration}>
                {({ errors, touched, values, setFieldValue, setErrors }) => (
                  <Form>
                    <div className='rounded p-2 pt-0 col-12 d-inline-block'>
                      <div><label className='form-label fw-bolder text-dark fs-6'>Question Types</label></div>
                      <Select name='questionTypeSelect' id='questionTypeSelect' options={tempQuestionTypeList} isMulti={true} onChange={updateQuestionTypes} />
                      {ErrorDisplay('questionTypes', errors)}
                      <div className='mt-4'><label className='form-label fw-bolder text-dark fs-6'>No of Questions</label></div>
                      <div className='col-12 d-inline-block'>
                        <Field type='number' className='form-control form-control-lg mb-1' id='noOfQuestions'
                          name='noOfQuestions' placeholder='No of questions' />
                      </div>
                      {ErrorDisplay('noOfQuestions', errors)}
                      <div className='mt-4'><label className='form-label fw-bolder text-dark fs-6'>Topic</label></div>
                      <div className='col-12 d-inline-block'>
                        {
                          topics.map((topic: string, index: number) => {
                            if (topic != '') {
                              return <>
                                {index != 1 ? <span style={{ fontWeight: 'bold', fontSize: '18px' }}>→</span> : <></>}
                                {index == topics.length - 1 ?
                                  <div className="btn-group mr-2" role="group" aria-label="First group">
                                    <button className='p-2 btn btn-dark'>{topic}</button>
                                    <button className='p-2 btn btn-danger me-2' onClick={()=>{setTopics(topics.slice(0,topics.length - 1))}}>
                                      <i className="fa fa-close" aria-hidden="true"></i>
                                    </button>
                                  </div> : <button className='p-2 btn btn-dark me-2'>{topic}</button>}
                              </>
                            }
                          })
                        }
                        {topicListForStream['JEE-NEET'][topics[topics.length - 1]]?.['children'] && topicListForStream['JEE-NEET'][topics[topics.length - 1]]?.['children'].length > 0 &&
                          <select className='form-select mt-2' onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                            updateTopics(event.target.value);
                            event.target.value = ''
                          }}>
                            <option value={''}>Select...</option>
                            {topicListForStream['JEE-NEET'][topics[topics.length - 1]]['children'].map((topic: string) => {
                              return <option value={topic}>{topic}</option>
                            })}
                          </select>
                        }
                        {/*<Select name='topicList' id='topicList' options={topicList('JEE-NEET', false)?.map((topic: string) => { return { value: topic, label: topic } })} isMulti={true} onChange={updateTopics} />*/}
                      </div>
                      {ErrorDisplay('topics', errors)}
                      <div className='mt-4'><label className='form-label fw-bolder text-dark fs-6'>Special Tags</label></div>
                      <div className='col-12 d-inline-block'>
                        <Select name='specialTags' id='specialTags' options={Object.keys(specialTagsForStream['JEE-NEET'])?.map((topic: string) => { return { value: topic, label: specialTagsForStream['JEE-NEET'][topic] } })} isMulti={true} onChange={() => { }} />
                      </div>
                    </div>
                    <button className='btn btn-primary float-end'>Add Configuration</button>
                  </Form>)
                }
              </Formik>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export { GenerateChallenge }
