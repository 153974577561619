/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../core/Auth'
import axios from 'axios'
import { getUserByToken } from '../core/_requests'


export function RegisterNotLogged() {
  const { saveAuth, setCurrentUser } = useAuth()
  let [error, setError] = useState<string>('');
  const update = () => {
    setError('')
    let name = (document.getElementById('name') as HTMLInputElement).value;
    if (!name || name === '') setError('Please enter the name to continue')
    axios.post(process.env.REACT_APP_API_URL + '/auth/tryon', { name: name }).then((reply) => {
      if (reply?.data) {
        saveAuth(reply.data)
        getUserByToken(reply.data.api_token).then((userData) => {
          if (userData?.data) {
            setCurrentUser(userData.data)
            window.location.reload()
          } else {
            //TODO
          }
        }).catch(() => {
          //TODO
        })
      } else {
        //TODO
      }
    }).catch(() => {
      //TODO
    })
  }

  return (
    <div className='m-5'>
      <h1 className='m-5 text-center'>Welcome!</h1>
      <h4 className='text-center'>Start you learning journey</h4>
      <div>
        {/*<div className='text-center'><label className='form-label fw-bolder text-dark fs-6 text-center'>Or just start with your name</label></div>*/}
        <input type='text' id='name' className='form-control form-control mb-1' placeholder='Full Name' />
        {error && error !== '' && <div>*{error}</div>}
        <div className='text-center'><button className='btn btn-primary' onClick={update}>Start</button></div>
      </div>
      <div className='text-center mt-10'>
        Already have an account? <Link to='/auth/login' className='m-3 fs-4'><b>Login</b></Link>
        {/*<Link to='/auth/register' className='btn btn-info m-5'>Register</Link>*/}
      </div>
    </div>
  )
}

