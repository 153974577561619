// DragAndDrop.tsx
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Latex from 'react-latex-next';

interface DragAndDropPropsMain {
  itemsIn: DragAndDropProps[];
  setFieldValue: any;
}
interface DragAndDropProps {
  id: string;
  items: DraggableItemProps[];
}

interface DraggableItemProps {
  id: string;
  name: string;
  index: number;
}

interface DroppableContainerProps {
  droppableId: string;
  items: DraggableItemProps[];
  isQuestion: boolean;
}

const DraggableItem: React.FC<DraggableItemProps> = ({ id, name, index }) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div className='m-2 p-2 rounded shadow d-inline-block'
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            border: '1px solid #ccc',
            padding: '8px',
            marginBottom: '8px',
            backgroundColor: 'white',
            ...provided.draggableProps.style,
          }}
        >
          <Latex>{name || ''}</Latex>
        </div>
      )}
    </Draggable>
  );
};

const DroppableContainer: React.FC<DroppableContainerProps> = ({ droppableId, items, isQuestion }) => {
  return (
    <Droppable droppableId={droppableId}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className={!isQuestion ? 'm-3 pt-1 rounded shadow border border-dark col' : 'col'} style={!isQuestion ? {
            padding: '16px',
            minHeight: '200px',
          } : { minHeight: items.length > 0 ? '100px' : '0px' }}
        >
          {!isQuestion && <h4 className='text-center'><Latex>{droppableId || ''}</Latex></h4>}
          {items.map((item, index) => (
            <DraggableItem key={item.id} {...item} index={index} />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
const DragDrop: React.FC<DragAndDropPropsMain> = ({ itemsIn, setFieldValue }) => {
  const [lists, setLists] = useState<DragAndDropProps[]>(itemsIn);
  /*
  [
  { id: 'question', items: [{ id: '1', name: 'Item 1', index: 0 }, 
  { id: '2', name: 'Item 2', index: 0 }, { id: '3', name: 'Item 3', index: 0 },
  { id: '4', name: 'Item 4', index: 0 }, { id: '5', name: 'Item 5', index: 0 },
  { id: '7', name: 'Item 7', index: 0 }, { id: '6', name: 'Item 6', index: 0 },
  { id: '8', name: 'Item 8', index: 0 }, { id: '9', name: 'Item 9', index: 0 },
  { id: '10', name: 'Item 10', index: 0 }, { id: '11', name: 'Item 11', index: 0 }] },
  { id: 'list1', items: [] },
  { id: 'list2', items: [] },
  { id: 'list3', items: [] },
]);*/

  const handleDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const sourceListId = result.source.droppableId;
    const destListId = result.destination.droppableId;
    console.log("dragdrop")
    if (sourceListId === destListId) {
      const updatedLists = lists.map((list) => {
        if (list.id === sourceListId) {
          const reorderedItems = Array.from(list.items);
          const [movedItem] = reorderedItems.splice(result.source.index, 1);
          reorderedItems.splice(result.destination.index, 0, movedItem);
          return { ...list, items: reorderedItems };
        }
        return list;
      });

      setLists(updatedLists);
      let answer: any = []
      updatedLists.forEach((drops: any, dropIndex: number) => {
        if (drops.id != 'question')
          drops.items.forEach((drags: any, dragIndex: number) => {
            answer.push(drags.index + '-' + (dropIndex - 1))
          })
      })
      setFieldValue('answer', answer)
    } else {
      const sourceList = lists.find((list) => list.id === sourceListId);
      const destList = lists.find((list) => list.id === destListId);

      if (sourceList && destList) {
        const updatedLists = lists.map((list) => {
          if (list.id === sourceListId) {
            const updatedSourceItems = Array.from(sourceList.items);
            updatedSourceItems.splice(result.source.index, 1);
            return { ...list, items: updatedSourceItems };
          } else if (list.id === destListId) {
            const updatedDestItems = Array.from(destList.items);
            updatedDestItems.splice(result.destination.index, 0, sourceList.items[result.source.index]);
            return { ...list, items: updatedDestItems };
          }
          return list;
        });

        setLists(updatedLists);
        let answer: any = []
        updatedLists.forEach((drops: any, dropIndex: number) => {
          if (drops.id != 'question')
            drops.items.forEach((drags: any, dragIndex: number) => {
              answer.push(drags.index + '-' + (dropIndex - 1))
            })
        })
        setFieldValue('answer', answer)
      }
    }
  };
  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className='d-flex m-2 p-2'>
          {lists.slice(0, 1).map((list) => (
            <DroppableContainer key={list.id} droppableId={list.id} items={list.items} isQuestion={true} />
          ))}
        </div>
        <div className='d-flex m-2'>
          {lists.slice(1).map((list) => (
            <DroppableContainer key={list.id} droppableId={list.id} items={list.items} isQuestion={false} />
          ))}
        </div>
      </DragDropContext>
    </>
  );
};

export default DragDrop;
