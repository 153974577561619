import axios from "axios";
import { useEffect, useState } from "react";

export const AllChallenges = () => {
  let [challenges, setChallenges] = useState<any>([]);
  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + '/get-challenge-list/').then((reply) => {
      if (reply?.data?.challenges) {
        setChallenges(reply.data.challenges);
      } else {
        //TODO
      }
    })
  },[])
  return (
    <>
    {challenges?.map((challenge: any) => {
      return <a key={challenge._id} href={'/create-module/' + challenge._id} className='card shadow p-5 mb-2'><h6>{challenge.heading}</h6></a>
    })}
    </>
  )
}