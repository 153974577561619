import axios, { AxiosResponse } from 'axios';
import { FC, useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Form, Formik, FormikValues, Field } from 'formik'
import { Section, sectionInit, ValidationSchema } from '../../models/Section'
import { ViewSection } from '../../modules/components/display/ViewSection';
import { ErrorDisplay } from '../../modules/components/common/ErrorDisplay';
import { DisplayLoader } from '../../utils/loader';
import { number } from 'yup';
import { useAuth } from '../../modules/auth';
import { Modal } from 'react-bootstrap';
import { getUserByToken } from '../../modules/auth/core/_requests';
import { systemSort } from '../../utils/systemSort';
type ApiParams = { id: string; sectionNo: string };
const Exercise: FC = () => {
  const { id, sectionNo } = useParams<ApiParams>();
  const { saveAuth, currentUser, setCurrentUser } = useAuth()
  let [isLoading, setIsLoading] = useState<boolean>(false);
  let [challenge, setChallenge] = useState<any>({ sections: [] });
  const [secondsLeft, setSecondsLeft] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);
  let [section, setSection] = useState<any>({});
  let [showNameModal, setShowNameModal] = useState<boolean>(false);
  let [attempt, setAttempt] = useState<any>({});
  let [attemptId, setAttemptId] = useState<any>('');
  let [currentSectionNo, setCurrentSectionNo] = useState<number>(-1);
  let [initValues, setInitValues] = useState<Section>(sectionInit);
  let [validationSchema, setValidationSchema] = useState<any>({});
  useEffect(() => {
    if (currentSectionNo !== -1) {
      if (document.getElementById('section')) {
        (document.getElementById('section') as HTMLElement).style.display = 'none'
      }
      if (document.getElementById('footer')) {
        (document.getElementById('footer') as HTMLElement).style.display = 'none'
      }
      getSection();
    }
  }, [currentSectionNo])
  useEffect(() => {
    if (sectionNo && sectionNo !== '') {
      setCurrentSectionNo(parseInt(sectionNo));
    } else {
      setCurrentSectionNo(0);
    }
    if (currentUser?.isNotLoggedIn && currentUser.name === currentUser.username) {
      setShowNameModal(true);
    }
  }, []);
  const updateName = async () => {
    let name = (document.getElementById('name') as HTMLInputElement).value;
    const { data: auth } = await axios.post(process.env.REACT_APP_API_URL + '/change-profile/', { id: currentUser?.id, name: name })
    saveAuth(auth)
    const { data: user } = await getUserByToken(auth.api_token)
    setCurrentUser(user)
    setShowNameModal(false);
  }
  const updateValidationSchema = (values: Section) => {
    setValidationSchema(ValidationSchema(values, true));
  }
  const parseTime = (time: number) => {
    return pad(Math.floor(time / 3600)) + ':' + pad(Math.floor(time / 60) % 60) + ':' + pad(time % 60)
  }
  const pad = (inputNumber: number) => {
    return (inputNumber < 10 ? ('0' + inputNumber) : inputNumber)
  }
  const resetTimer = (time: number, attemptId: string, totalTime: number) => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
    setSecondsLeft(time);
    const newIntervalId = setInterval(() => {
      setSecondsLeft(secondsLeft => {
        secondsLeft = (secondsLeft - 1 > 0 ? secondsLeft - 1 : 0)
        if ((secondsLeft % 3 === 0) && window.location.pathname.split('/')[1] === 'test') {
          updateTime(attemptId, secondsLeft, totalTime)
        };
        if (secondsLeft === 0) {
          finishAttempt();
        }
        return secondsLeft;
      });
    }, 1000);
    setIntervalId(newIntervalId);
  }
  const getSection = (shouldNotReload?: boolean) => {
    DisplayLoader(true, '')
    if (!shouldNotReload) setIsLoading(true);
    axios.post(process.env.REACT_APP_API_URL + '/get-section/',
      { challengeInstanceId: id, type: window.location.pathname.split("/")[1], sectionPosition: currentSectionNo }).then((reply) => {
        console.log(reply.data)
        if (reply?.data) {
          if (reply.data.redirect) {
            window.location.pathname = reply.data.redirect;
          } else {
            if (reply.data.section?.type === 'pair') {
              reply.data.section.jumbledOptions = systemSort([...reply.data.section.options]);
            }
            console.log(reply.data)
            setChallenge(reply.data.challenge);
            setSection(reply.data.section);
            setAttempt(reply.data.sectionAttempt);
            setAttemptId(reply.data.attemptId);
            if (reply.data.sectionAttempt && !reply.data.sectionAttempt.answer && !(reply.data.section?.type === 'mcq' || reply.data.section?.type === 'multiple')) reply.data.sectionAttempt.answer = '';
            setInitValues(reply.data.sectionAttempt ? reply.data.sectionAttempt : { answer: (!(reply.data.section?.type === 'mcq' || reply.data.section?.type === 'multiple') ? '' : null) });
            updateValidationSchema(reply.data.section);
            if (window.location.pathname.split("/")[1] === 'test') {
              resetTimer(reply.data.challenge.totalTime - reply.data.challenge.timeUsed, reply.data.attemptId, reply.data.challenge.totalTime);
            }
            setTimeout(() => {
              if ((document.getElementById('totalPoints') as HTMLElement)) {
                (document.getElementById('totalPoints') as HTMLElement).classList.remove('animate__heartBeat');
                //(document.getElementById('plusFive') as HTMLElement).style.display = 'inline-block'
                setTimeout(() => {
                  (document.getElementById('totalPoints') as HTMLElement).classList.add('animate__heartBeat');
                }, 100)
              }
            }, 100)
            setTimeout(() => {
              (document.getElementById('section') as HTMLElement).style.display = 'block';
              (document.getElementById('footer') as HTMLElement).style.display = 'block';
            }, 10)
          }
        } else {
          //TODO
        }
        setIsLoading(false);
        DisplayLoader(false, '')
      }, (error) => {
        setIsLoading(false);
        DisplayLoader(false, '')
        //reject(error)//TODO
      });
  }
  const updateTime = (attemptId: string, secondsLeft: number, totalTime: number) => {
    if (attemptId) {
      axios.post(process.env.REACT_APP_API_URL + '/update-time/' + attemptId, { timeUsed: totalTime - secondsLeft }).then((reply) => {
      }).catch(() => {
        //TODO
      })
    }
  }
  const clearAnswer = () => {

    axios.post(process.env.REACT_APP_API_URL + '/clear-answer/' + attemptId + '/' + section._id).then((reply) => {
      getSection(true);
    }).catch(() => {
      //TODO
    })

  }
  const checkAnswer = (values: FormikValues) => {
    DisplayLoader(true, '')
    console.log("here")
    axios.post(process.env.REACT_APP_API_URL + '/check-answer/' + attemptId + '/' + section._id, values).then((reply) => {
      if (reply?.data) {
        if (reply.data.result === true) {
          (document.getElementById('plusFive') as HTMLElement).style.display = 'inline-block'
          new Audio('/audio/win.mp3').play();
          setTimeout(()=>{
            (document.getElementById('plusFive') as HTMLElement).style.display = 'none'
          },1000)
        } else if (reply.data.result === false) {
          new Audio('/audio/lose.mp3').play();
        }
        getSection(true);
        if (reply.data.result !== null && document.getElementById('totalPoints')) {

        }
        //setChallenge(reply.data.challenge);
        //setSection(reply.data.section);
        //updateValidationSchema(reply.data.section);
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }
  const finishAttempt = () => {
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/finish-attempt/' + attemptId + '/' + section._id).then((reply) => {
      if (reply?.data?.completed) {
        window.location.pathname = '/reports/' + id;
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }
  return (
    <div className='border-top border-dark border-2 p-2 pb-0 ms-3 me-3' style={{ marginBottom: "100px" }}>
      <div className='rounded p-2 text-center'>
        <img
          className='mw-100 mh-200px' alt='olearno' src={'/media/illustrations/dozzy-1/1.png'}
          style={{ position: 'fixed', right: '0', opacity: '0.5', zIndex: -1, bottom: '30px' }}
        />
        <img alt='olearno' src={'/media/icons/duotune/abstract/abs026.svg'}
          style={{ position: 'absolute', left: '0', zIndex: -1, top: '80px' }}
        />

        {currentUser?.role !== "student" && <div>
          <Link type='button' to={'/composite-reports/' + id} className='btn btn-info m-1 float-end p-2 shadow-lg'>
            <i className="fa fa-chart-line"></i><small>Reports</small>
          </Link>
          <Link className='btn btn-dark mb-1 float-start' to={'/create-module/' + challenge._id}>Edit This Module</Link>
        </div>
        }
        <h4>
          {challenge.heading}
        </h4>

      </div>
      {section.type && !isLoading && <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
        validationSchema={validationSchema} initialValues={initValues} onSubmit={checkAnswer}>
        {({ errors, touched, values, setFieldValue, setErrors }) => (
          <Form >
            <hr className='m-2' />
            <div className='col-12 d-inline-block p-1 align-top'>
              <div>
                <div className='text-center mb-12'>
                  <div className='d-inline-block text-center text-dark shadow-lg rounded p-4 pb-1 mb-5 float-start'>
                    <b>{currentSectionNo + 1}</b>/{challenge.totalSections}<br />
                    <h6>Section</h6>
                  </div>

                  {(window.location.pathname.split("/")[1] === 'assignment' || window.location.pathname.split("/")[1] === 'test') ? <button type='button' className='btn btn-danger' data-bs-toggle="modal" data-bs-target="#finishModal">Finish</button> : <button type='button' className='btn btn-danger' style={{ opacity: 0 }} disabled>Finish</button>}

                  {window.location.pathname.split("/")[1] !== 'test' && <><div id='totalPoints' className='d-inline-block float-end bg-success text-light text-center shadow-lg rounded p-4 pb-1 mb-5 animate__animated animate__slow'>
                    <b>{challenge.currentPoints}</b>/{challenge.totalPoints}<br />
                    <h6 className='text-light'>Total Points</h6>
                  </div>
                    <div id="plusFive" className="rounded p-3 float-end bg-info text-light text-center animate__animated animate__fadeOutUp animate__slow" style={{display: 'none', position: 'relative', top: "50px",right:"-75px",transform: 'translateY(100px)'}}>+{attempt?.points}</div>
                  </>}
                  {window.location.pathname.split("/")[1] === 'test' && <div className={'d-inline-block float-end text-light text-center shadow-lg rounded p-4 pb-1 mb-5' + (secondsLeft < 5 * 60 ? ' bg-danger' : ' bg-success')}>
                    <b>{parseTime(secondsLeft)}</b>
                    <h6 className='text-light'>Time Left</h6>
                  </div>}
                </div>
                <div id='section' style={{ fontFamily: 'Montserrat' }}>
                  {ViewSection(section, attempt && ((attempt.correct !== null) && window.location.pathname.split('/')[1] !== 'test') ? true : false, 1, errors, values, setFieldValue, attempt, true, checkAnswer)}
                </div>
                <div id='footer'>
                  {attempt && (attempt.answer || (section.type === 'pair' && attempt.correct) || (section.type === 'crossword' && attempt.correct)) && section.type !== 'long-text' &&
                    <div className='mt-3'>
                      {attempt.correct && <div className='p-5 bg-success text-center rounded animate__animated animate__bounceInUp' style={{ animationDelay: '0.05s' }}><h4 className='text-white'>Correct</h4></div>}
                      {attempt.correct === false && <div className='p-5 bg-danger text-center rounded animate__animated animate__bounceInUp' style={{ animationDelay: '0.05s' }}><h4 className='text-white'>Wrong</h4></div>}
                    </div>
                  }
                  {!(attempt && attempt.answer && window.location.pathname.split('/')[1] !== 'test') && section.type !== 'pair' && section.type !== '7lives' && section.type !== 'crossword' && section.type !== 'slide' && section.type !== 'mindmapactivity' && section.type !== 'mindmapslide' && section.type !== 'simulation' &&
                    <div className="d-grid gap-2">
                      <button type='submit' className='btn btn-block btn-dark m-3 animate__animated animate__bounceInUp' style={{ animationDelay: '0.75s' }}>Submit</button>
                    </div>
                  }
                  {(attempt && attempt.answer && window.location.pathname.split('/')[1] === 'test') &&
                    <div className="d-grid gap-2">
                      <button type='button' className='btn btn-block btn-primary m-3' onClick={clearAnswer}>Clear Answer</button>
                    </div>
                  }
                  <button disabled={currentSectionNo === 0} type='button' className='btn btn-dark shadow float-start me-2 mt-4' onClick={() => { setCurrentSectionNo(currentSectionNo - 1) }}>Prev</button>
                  <button type='button' disabled={currentSectionNo === challenge.totalSections - 1} className='btn btn-dark shadow float-end mt-4' onClick={() => { setCurrentSectionNo(currentSectionNo + 1) }}>Next</button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>}
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showNameModal} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowNameModal(false) }}>
        <div className='modal-content'>
          <div className="modal-body">
            <h3 className='m-5 text-center'>Update Name</h3>
            <div>
              <label className='form-label fw-bolder text-dark fs-6'>Name</label>
              <input type='text' id='name' className='form-control form-control mb-1' />
            </div>
            <button className='btn btn-primary float-end' onClick={updateName}>Save</button>
          </div>
        </div>
      </Modal>
      <div className="modal fade" id="finishModal" aria-labelledby="finishModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="finishModalLabel">Finish Challenge</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <h5>Are you sure that you want to finish this challenge?</h5>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary float-start" data-bs-dismiss="modal">No</button>
              <button type="button" className="btn btn-danger" onClick={() => finishAttempt()} data-bs-dismiss="modal">Yes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Exercise }
