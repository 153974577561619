// SingleColumnDragAndDrop.tsx
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Latex from 'react-latex-next';
interface DragAndDropProps {
  itemsIn: DraggableItemProps[];
  setFieldValue: any;
}
interface DraggableItemProps {
  id: string;
  name: string;
  index: number;
}

const DraggableItem: React.FC<DraggableItemProps> = ({ id, name, index }) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            border: '1px solid #ccc',
            padding: '8px',
            marginBottom: '8px',
            backgroundColor: 'white',
            ...provided.draggableProps.style,
          }}
        >
          <Latex>{name || ''}</Latex>
        </div>
      )}
    </Draggable>
  );
};
const SequenceVertical: React.FC<DragAndDropProps> = ({ itemsIn, setFieldValue }) => {
  const [items, setItems] = useState<DraggableItemProps[]>(itemsIn);

  const handleDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = Array.from(items);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    setItems(reorderedItems.map((item, index) => ({ ...item, index })));
    setFieldValue('answer', reorderedItems.map((item) => item.name))
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              padding: '16px',
              minHeight: '100px',
            }}
          >
            {items.map((item, index) => (
              <DraggableItem key={item.id} {...item} index={index} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default SequenceVertical;
