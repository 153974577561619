import { FC, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { useAuth } from '../../modules/auth';
import { generateOtp } from '../../utils/generateOTP';

export const Settings: FC = () => {
  const { currentUser } = useAuth()
  const [success, setSuccess] = useState('')
  const [password, setPassword] = useState('')
  useEffect(() => {
    updatePassword()
  }, []);
  const copy = async () => {
    navigator.clipboard.writeText(window.location.origin + '/join-organisation/' + currentUser?.institutionId);
    setSuccess('Link copied successfully. Kindly share it with individuals interested in joining your organization.')
  }
  const save = async (values: any) => {
  }
  const updatePassword = async () => {
    setPassword(generateOtp(currentUser?.username || '').toString())
  }
  return (
    <div className='rounded border border-dark p-2 pb-0 shadow-lg bg-light shadow'>
      <div className='shadow mb-4 rounded row m-2 p-4'>
        <div className='col-6'><h5>Admin Password</h5></div>
        <div className='col-6'>
          <div>{password}<button className='btn btn-primary p-2 ms-1' onClick={updatePassword}>Update</button></div>
        </div>
      </div>
      <div className='shadow mb-4 rounded row m-2 p-2'>
        <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
          validationSchema={{}} initialValues={{}} onSubmit={save}>
          {({ errors, touched, values, setFieldValue, setErrors }) => (
            <Form>
              {success && success !== '' && (
                <div className='mb-lg-15 alert alert-success text-center'>
                  <div className='alert-text font-weight-bold'>
                    {success}
                  </div>
                </div>
              )}
              <div className='bg-light rounded p-2 col-12 d-inline-block'>
                <div><label className='form-label fw-bolder text-dark fs-6'>Invite registered users to join your organisation.</label></div>
                <button type='button' className='btn btn-success' onClick={() => { copy() }}>Copy Invite Link</button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}