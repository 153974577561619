import { useEffect, useRef, useState } from 'react';
import { Annotorious } from '@recogito/annotorious';
import SelectorPack from "@recogito/annotorious-selector-pack";
import Latex from 'react-latex-next';
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import '@recogito/annotorious/dist/annotorious.min.css';

export const AnnotatableImageView = ({ section, disabled, values, attempt, setFieldValue }) => {
  const imgEl = useRef();
  const [anno, setAnno] = useState();
  const [text, setText] = useState('');
  const [image, setImage] = useState('');

  useEffect(() => {
    let annotorious = null;
    if (section.text) {
      setText(section.text.split(':image:')[0])
      setImage(section.text.split(':image:')[1])
    }
    setTimeout(() => {
      if (imgEl.current) {
        // Init
        annotorious = new Annotorious({
          image: imgEl.current,
          allowEmpty: true,
          disableEditor: true,
          readOnly: true,
          widgets: []
        });
        setAnno(annotorious);
        /*
        if (annotorious && section.options?.length > 0) {
          annotorious.setAnnotations(section.options);
        }
        */
      }
    }, 500)

    // Keep current Annotorious instance in state

    // Cleanup: destroy current instance
    return () => { annotorious?.destroy() };
  }, []);
  useEffect(()=>{
    console.log("selectoptions")
    if (anno && section.options) {
      anno.setAnnotations(section.options);
      if (attempt && attempt.answer && attempt.correct != null) {
        setTimeout(() => {
          anno.selectAnnotation(section.answer)
        }, 300)
      }
    }
  },[anno, section.options])
  useEffect(() => {
    if (anno) {
      if (attempt && attempt.answer && attempt.correct == false) {
        setTimeout(() => {
          if (document.querySelector('g[data-id="' + attempt.answer + '"]')) {
            document.querySelector('g[data-id="' + attempt.answer + '"]').classList.add('annotate-error-select');
          }
        }, 500)
      }
      anno.on('cancelSelected', annotation => {
        if (attempt && attempt.answer && (attempt.correct != null)) {
          setTimeout(() => {
            anno.selectAnnotation(section.answer)
          }, 100)
        }
      })
      anno.on('selectAnnotation', annotation => {
        console.log("here")
        if (attempt && attempt.answer && (attempt.correct != null)) {
          anno.selectAnnotation(section.answer)
        } else {
          setFieldValue('answer', annotation.id)
        }
      });
    }
  }, [anno, attempt])
  return (
    <>
      <div className='text-center'>
        <b><Latex>{text || ''}</Latex></b>
      </div>
      <div className='text-center'>
        {image != '' &&
          <img
            ref={imgEl}
            src={image}
          />
        }
      </div>
    </>
  );
}

export default AnnotatableImageView;