import axios, { AxiosResponse } from 'axios';
import { FC, useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Form, Formik, FormikValues, Field } from 'formik'
import { Section, sectionInit, ValidationSchema } from '../../models/Section'
import { ViewSection } from '../../modules/components/display/ViewSection';
import { ErrorDisplay } from '../../modules/components/common/ErrorDisplay';
import { DisplayLoader } from '../../utils/loader';
import { number } from 'yup';
import { useAuth } from '../../modules/auth';
import { Modal } from 'react-bootstrap';
import { getUserByToken } from '../../modules/auth/core/_requests';
import { systemSort } from '../../utils/systemSort';
import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';
import Latex from 'react-latex-next';
type ApiParams = { id: string; sectionNo: string };
const NTATest: FC = () => {
  const { id, sectionNo } = useParams<ApiParams>();
  const { saveAuth, currentUser, setCurrentUser } = useAuth()
  let [isLoading, setIsLoading] = useState<boolean>(false);
  let [challenge, setChallenge] = useState<any>({ sections: [] });
  const [secondsLeft, setSecondsLeft] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);
  let [section, setSection] = useState<any>({});
  let [showNameModal, setShowNameModal] = useState<boolean>(false);
  let [attempt, setAttempt] = useState<any>({});
  let [attemptId, setAttemptId] = useState<any>('');
  let [currentSectionNo, setCurrentSectionNo] = useState<number>(-1);
  let [initValues, setInitValues] = useState<Section>(sectionInit);
  let [validationSchema, setValidationSchema] = useState<any>({});
  useEffect(() => {
    if (currentSectionNo !== -1) {
      if (document.getElementById('section')) {
        (document.getElementById('section') as HTMLElement).style.display = 'none'
      }
      if (document.getElementById('footer')) {
        (document.getElementById('footer') as HTMLElement).style.display = 'none'
      }
      getSection();
    }
  }, [currentSectionNo])
  useEffect(() => {
    if (sectionNo && sectionNo !== '') {
      setCurrentSectionNo(parseInt(sectionNo));
    } else {
      setCurrentSectionNo(0);
    }
    if (currentUser?.isNotLoggedIn && currentUser.name === currentUser.username) {
      setShowNameModal(true);
    }
  }, []);
  const updateName = async () => {
    let name = (document.getElementById('name') as HTMLInputElement).value;
    const { data: auth } = await axios.post(process.env.REACT_APP_API_URL + '/change-profile/', { id: currentUser?.id, name: name })
    saveAuth(auth)
    const { data: user } = await getUserByToken(auth.api_token)
    setCurrentUser(user)
    setShowNameModal(false);
  }
  const updateValidationSchema = (values: Section) => {
    setValidationSchema(ValidationSchema(values, true));
  }
  const parseTime = (time: number) => {
    return pad(Math.floor(time / 3600)) + ':' + pad(Math.floor(time / 60) % 60) + ':' + pad(time % 60)
  }
  const pad = (inputNumber: number) => {
    return (inputNumber < 10 ? ('0' + inputNumber) : inputNumber)
  }
  const resetTimer = (time: number, attemptId: string, totalTime: number) => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
    setSecondsLeft(time);
    const newIntervalId = setInterval(() => {
      setSecondsLeft(secondsLeft => {
        secondsLeft = (secondsLeft - 1 > 0 ? secondsLeft - 1 : 0)
        if ((secondsLeft % 3 === 0) && window.location.pathname.split('/')[1].indexOf('test') != -1) {
          //updateTime(attemptId, secondsLeft, totalTime)
        };
        if (secondsLeft === 0) {
          finishAttempt();
        }
        return secondsLeft;
      });
    }, 1000);
    setIntervalId(newIntervalId);
  }
  const getSection = (shouldNotReload?: boolean) => {
    DisplayLoader(true, '')
    if (!shouldNotReload) setIsLoading(true);
    axios.post(process.env.REACT_APP_API_URL + '/get-section/',
      { challengeInstanceId: id, type: 'test', sectionPosition: currentSectionNo }).then((reply) => {
        console.log(reply.data)
        if (reply?.data) {
          if (reply.data.redirect) {
            window.location.pathname = reply.data.redirect;
          } else {
            if (reply.data.section?.type === 'pair') {
              reply.data.section.jumbledOptions = systemSort([...reply.data.section.options]);
            }
            console.log(reply.data)
            setChallenge(reply.data.challenge);
            setSection(reply.data.section);
            setAttempt(reply.data.sectionAttempt);
            setAttemptId(reply.data.attemptId);
            if (reply.data.sectionAttempt && !reply.data.sectionAttempt.answer && !(reply.data.section?.type === 'mcq' || reply.data.section?.type === 'multiple')) reply.data.sectionAttempt.answer = '';
            setInitValues(reply.data.sectionAttempt ? reply.data.sectionAttempt : { answer: (!(reply.data.section?.type === 'mcq' || reply.data.section?.type === 'multiple') ? '' : null) });
            updateValidationSchema(reply.data.section);
            if (window.location.pathname.split("/")[1].indexOf('test') != -1) {
              resetTimer(reply.data.challenge.totalTime - reply.data.challenge.timeUsed, reply.data.attemptId, reply.data.challenge.totalTime);
            }
            setTimeout(() => {
              if ((document.getElementById('totalPoints') as HTMLElement)) {
                (document.getElementById('totalPoints') as HTMLElement).classList.remove('animate__heartBeat');
                //(document.getElementById('plusFive') as HTMLElement).style.display = 'inline-block'
                setTimeout(() => {
                  (document.getElementById('totalPoints') as HTMLElement).classList.add('animate__heartBeat');
                }, 100)
              }
            }, 100)
            setTimeout(() => {
              (document.getElementById('section') as HTMLElement).style.display = 'block';
              (document.getElementById('footer') as HTMLElement).style.display = 'block';
            }, 10)
          }
        } else {
          //TODO
        }
        setIsLoading(false);
        DisplayLoader(false, '')
      }, (error) => {
        setIsLoading(false);
        DisplayLoader(false, '')
        //reject(error)//TODO
      });
  }
  const updateTime = (attemptId: string, secondsLeft: number, totalTime: number) => {
    if (attemptId) {
      axios.post(process.env.REACT_APP_API_URL + '/update-time/' + attemptId, { timeUsed: totalTime - secondsLeft }).then((reply) => {
      }).catch(() => {
        //TODO
      })
    }
  }
  const clearAnswer = () => {

    axios.post(process.env.REACT_APP_API_URL + '/clear-answer/' + attemptId + '/' + section._id).then((reply) => {
      getSection(true);
    }).catch(() => {
      //TODO
    })

  }
  const checkAnswer = (values: FormikValues) => {
    DisplayLoader(true, '')
    console.log("here")
    axios.post(process.env.REACT_APP_API_URL + '/check-answer/' + attemptId + '/' + section._id, values).then((reply) => {
      if (reply?.data) {
        if (reply.data.result === true) {
          (document.getElementById('plusFive') as HTMLElement).style.display = 'inline-block'
          new Audio('/audio/win.mp3').play();
          setTimeout(() => {
            (document.getElementById('plusFive') as HTMLElement).style.display = 'none'
          }, 1000)
        } else if (reply.data.result === false) {
          new Audio('/audio/lose.mp3').play();
        }
        getSection(true);
        if (reply.data.result !== null && document.getElementById('totalPoints')) {

        }
        //setChallenge(reply.data.challenge);
        //setSection(reply.data.section);
        //updateValidationSchema(reply.data.section);
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }
  const finishAttempt = () => {
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/finish-attempt/' + attemptId + '/' + section._id).then((reply) => {
      if (reply?.data?.completed) {
        window.location.pathname = '/reports/' + id;
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }
  return (
    <div className='border-top border-dark border-2 p-2 pb-0 ms-3 me-3 row bg-white' style={{ marginBottom: "100px" }}>
      <div className='col-12 mb-4'>
        <table className='' style={{ fontSize: '16px' }}>
          <tr style={{ height: '30px' }}>
            <td><span className='p-4'>Exam Name</span></td>
            <td> : <span style={{ color: '#f7931e', fontWeight: 'bold' }}>{challenge.heading}</span></td>
          </tr>
          <tr style={{ height: '30px' }}>
            <td><span className='p-4'>Remaining Time</span></td>
            <td> : <span className='p-2' style={{ color: 'white', fontWeight: 'bold', borderRadius: '10%', backgroundColor: '#0098DA' }}>{parseTime(secondsLeft)}</span></td>
          </tr>
        </table>
      </div>
      <div className='col-md-8 col-12 p-2'>
        {section.type && !isLoading && <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
          validationSchema={validationSchema} initialValues={initValues} onSubmit={checkAnswer}>
          {({ errors, touched, values, setFieldValue, setErrors }) => (
            <Form >
              <div id='section' style={{ height: '300px', overflow: 'auto' }}>
                <h2>Question {currentSectionNo + 1} :</h2>
                <hr />
                <div className=''>
                  <h4 style={{ fontWeight: 'normal' }}><Latex>{section.text ? section.text : ''}</Latex></h4>
                </div>
                <div><label className='form-label fw-bolder text-dark fs-6'>Options</label></div>
                {section?.options?.map((option: any, index: number) => {
                  return (
                    <>
                      <div className='row m-1 justify-content-center align-bottom rounded pt-4' key={index}>
                        <label>
                          <div className='col-11 d-inline-block'>{index + 1}. <Latex>{option || ''}</Latex></div>
                        </label>
                        {/* <label className={'shadow p-2 rounded-bottom border pt-1 ' + (((values && index == values.answer) && !(attempt && attempt.answer)) ? ' border-3 border-primary' : 'border-dark')}>
                          <div className='col-11 d-inline-block'><Latex>{option || ''}</Latex></div>
                          <div className='col-1 text-center d-inline-block' >
                            <Field type='radio' name='answer' value={'v' + index.toString()} style={{ height: "20px", width: "20px", opacity: '0' }} />
                          </div>
                        </label> */}
                      </div>
                    </>
                  )
                })}
                <div className='mb-2' style={{ marginTop: '40px' }}>
                  {section?.options?.map((option: any, index: number) => {
                    return <span className='m-2' style={{ fontSize: '16px', display: 'inline-flex', alignItems: 'center' }}>
                      <span style={{ marginTop: '5px' }}><Field type='radio' name='answer' value={'v' + index.toString()} style={{ height: "20px", width: "20px" }} /> </span>
                      <span className='ms-1'>{(index + 1) + ')'}</span>
                    </span>
                  })}
                </div>
              </div>
              <div id='footer'>
                <div className='text-center'>
                  <button className='btn-nta btn-success-nta m-1 border'>SAVE AND NEXT</button>
                  <button className='btn-nta btn-default-nta m-1 border'>CLEAR</button>
                  <button className='btn-nta btn-warning-nta m-1 border'>SAVE & MARK FOR REVIEW</button>
                  <button className='btn-nta btn-primary-nta m-1 border'>MARK FOR REVIEW & NEXT</button>
                </div>
                <div className='bg-light p-3 border border-top'>
                  <button className='btn-nta btn-default-nta m-1 border' disabled={currentSectionNo == 0} onClick={()=>{setCurrentSectionNo(currentSectionNo - 1)}}> &lt;&lt; BACK </button>
                  <button className='btn-nta btn-default-nta m-1 border' disabled={currentSectionNo == (challenge.totalSections - 1)} onClick={()=>{setCurrentSectionNo(currentSectionNo + 1)}}>NEXT &gt;&gt;</button>
                  <button className='btn-nta btn-success-nta m-1 border float-end'>SUBMIT</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>}
      </div>
      <div className='col-md-4 col-12'>
        <div className="panel panel-body panel-default mb0 mt10 p-4" style={{ border: 'dotted' }}>
          <div className="table-responsive">
            <table className="table table-borderless mb0">
              <thead>
                <tr>
                  <td className="full-width"> <a className="p-2 text-dark" style={{ background: 'url(/media/nta/Logo1.png)', backgroundSize: '100% 100%', fontSize: '16px' }}>87</a></td>
                  <td>Not Visited</td>
                  <td className="full-width"> <a className="p-2 text-light" style={{ background: 'url(/media/nta/Logo2.png)', backgroundSize: '100% 100%', fontSize: '16px' }}>31</a> </td>
                  <td>Not Answered</td>
                </tr>
                <tr>
                  <td className="full-width"> <a className="p-2 text-light" style={{ background: 'url(/media/nta/Logo3.png)', backgroundSize: '100% 100%', fontSize: '16px' }}>23</a>  </td>
                  <td>Answered</td>
                  <td className="full-width"> <a className="p-2 text-light" style={{ background: 'url(/media/nta/Logo4.png)', backgroundSize: '100% 100%', fontSize: '16px' }}>12</a>  </td>
                  <td>Marked for Review</td>
                </tr>
                <tr>
                  <td className='full-width'> <a className="p-2 text-light" style={{ background: 'url(/media/nta/Logo5.png)', backgroundSize: '100% 100%', fontSize: '16px' }}>17</a>  </td>
                  <td colSpan={3}>Answered &amp; Marked for Review (will be considered for evaluation)</td>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        <div className="panel panel-body panel-default mb0 mt10 p-4" >
          {
            challenge?.attemptSummary?.map((attempt: any, index: number) => {
              return <button onClick={()=>{setCurrentSectionNo(index)}} className={"p-2 m-1 text-" + (attempt == 1 ? 'dark' : 'light')} style={{ width: '40px', height: '40px', backgroundImage: 'url(/media/nta/Logo'+ attempt +'.png)', backgroundColor: 'white', backgroundSize: '100% 100%', fontSize: '16px', border: 'none' }}>{index < 9 ? ('0' + (index + 1)) : (index + 1)}</button>
              // switch (attempt) {
              //   case 2:
                  
              //   case 3:
              //     return <button onClick={()=>{setCurrentSectionNo(index)}} className="p-2 m-1 text-light" style={{ width: '50px', height: '50px', background: 'url(/media/nta/Logo3.png)', backgroundSize: '100% 100%', fontSize: '16px', border: 'none' }}>{index < 9 ? ('0' + (index + 1)) : (index + 1)}</button>
              //   case 4:
              //     return <button onClick={()=>{setCurrentSectionNo(index)}} className="p-2 m-1 text-light" style={{ width: '50px', height: '50px', background: 'url(/media/nta/Logo4.png)', backgroundSize: '100% 100%', fontSize: '16px', border: 'none' }}>{index < 9 ? ('0' + (index + 1)) : (index + 1)}</button>
              //   case 5:
              //     return <button onClick={()=>{setCurrentSectionNo(index)}} className="p-2 m-1 text-light" style={{ width: '50px', height: '50px', background: 'url(/media/nta/Logo5.png)', backgroundSize: '100% 100%', fontSize: '16px', border: 'none' }}>{index < 9 ? ('0' + (index + 1)) : (index + 1)}</button>
              //   default:
              //     return <button onClick={()=>{setCurrentSectionNo(index)}} className="p-2 m-1 text-dark" style={{ width: '50px', height: '50px', background: 'url(/media/nta/Logo1.png)', backgroundSize: '100% 100%', fontSize: '16px', border: 'none' }}>{index < 9 ? ('0' + (index + 1)) : (index + 1)}</button>
              // }
            })
          }
        </div>
      </div>
    </div>
  )
}

export { NTATest }
