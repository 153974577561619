/* eslint-disable jsx-a11y/anchor-is-valid */
import { TextEditor } from '../common/TextEditor'
import Select from 'react-select'
import { Section, ValidationSchema } from '../../../models/Section'
import { Field, FormikErrors } from 'formik'
import { ErrorDisplay } from '../common/ErrorDisplay'
import { number } from 'yup'
import { topicChildren, topicList, topicParents } from '../../../models/LocalLists'
import { useState } from 'react'
import AnnotatableImage from './ImageAnnotation'
import { SevenLives } from './displayquestiontypes/7lives'
import SimulationGraph from './SimulationGraph'
import MindMap from './MindMap'
import { Modal } from 'react-bootstrap'

export const EditSection = (section: Section, errors: FormikErrors<Section>, setFieldValue: Function, setValidationSchema?: Function) => {
  {/*const [selectedOption, setSelectedOption] = useState(null);*/ }
  let selectedOption = null;
  const updateValidationSchema = (values: Section) => {
    if (setValidationSchema) {
      setValidationSchema(ValidationSchema(values, false, true));
    }
  }
  const addOptions = () => {
    if (!section.options) section.options = [];
    section.options.splice(section.options.length, 0, "")
    setFieldValue("options", section.options);
  }
  const addOptionsMatrix = () => {
    if (!section.options) section.options = [];
    section.options.splice(section.options.length, 0, ["", ""])
    setFieldValue("options", section.options);
  }
  const deleteOptions = (index: number) => {
    section.options.splice(index, 1);
    setFieldValue("options", section.options);
    if (section.answer === index) {
      section.answer = "";
      setFieldValue("answer", section.answer);
    }
  }
  const updateOptions = (index: any, value: any, index2?: any) => {
    if (index2 != null) {
      if (section.options[index] === null) section.options[index] = []
      if (JSON.stringify(section.options[index][index2]) !== JSON.stringify(value)) {
        section.options[index][index2] = value;
        setFieldValue("options", section.options);
      }
    } else {
      if (section.options[index] !== value) {
        section.options[index] = value;
        setFieldValue("options", section.options);
      }
    }
  }
  const updateOptionsBulk = (value: any) => {
    setFieldValue("options", value);
  }
  const updateText = (index: any, value: any) => {
    if (section.text !== value) {
      section.text = value;
      setFieldValue("text", section.text);
    }
  }
  const updateExplanation = (index: any, value: any) => {
    if (section.explanation !== value) {
      section.explanation = value;
      setFieldValue("explanation", section.explanation);
    }
  }
  const updateAnswer = (index: any, value: any) => {
    console.log("here")
    if (section.answer !== value) {
      section.answer = value;
      setFieldValue("answer", section.answer);
    }
  }
  const fixErrors = () => {
    if (section.text) {
      section.text = section.text.replaceAll(/&amp;(?!(?:[^<]+>|[^>]+<img))/g, "")
      setFieldValue("text", section.text);
    }
    if (section.explanation) {
      section.explanation = section.explanation.replaceAll(/&amp;(?!\bwidth\b)/g, "")
      setFieldValue("explanation", section.explanation);
    }
    if (section.answer && typeof section.answer === 'string') {
      section.answer = section.answer.replaceAll(/&amp;(?!\bwidth\b)/g, "")
      setFieldValue("answer", section.answer);
    }
    if (section.options && section.options.length > 0) {
      section.options = section.options.map((opt: string) => { return opt.replaceAll(/&amp;(?!\bwidth\b)/g, "") })
      setFieldValue("options", section.options);
    }
  }
  const addTags = (values: any) => {
    let topic = values?.map((value: any) => { return value.value })[0];
    if (!topic || topic === '') return
    let parents = topicParents('JEE-NEET', topic)
    let tags = [...section.tags, ...[topic]]
    if (parents && parents.length !== 0) tags = [...section.tags, ...[topic], ...parents]
    tags = tags.filter((value, index, self) => {
      return self.indexOf(value) === index;
    })
    setFieldValue("tags", tags);
    selectedOption = null;
    //setSelectedOption(null);
  }
  const removeTags = (tag: string) => {
    let children: any = topicChildren('JEE-NEET', tag) || [];
    if (tag === '') return;
    let newTags = [...section.tags]
    newTags = newTags.filter(item => ![...children, ...[tag]].includes(item));
    setFieldValue("tags", newTags);
  }
  const updateDifficultyLevel = (difficulty: number) => {
    setFieldValue("difficultyLevel", difficulty);
  }
  const addAutoOptions = (type: string) => {
    if (type == 'tf') {
      setFieldValue('options', ['True', 'False'])
    }
    if (type == 'ar') {
      setFieldValue('options', [
        'Both assertion (A) and reason (R) are true and reason (R) is the correct explanation of assertion (A)',
        'Both assertion (A) and reason (R) are true and reason (R) is NOT the correct explanation of assertion (A)',
        'Assertion (A) is true but reason (R) is false. ',
        'Assertion (A) is false but reason (R) is true.'])
    }
  }
  const moreOptions = () => {
    let moreOptionsElement = document.getElementById('more-options') as HTMLElement
    if (moreOptionsElement) {
      if (moreOptionsElement.style.display == 'none') {
        moreOptionsElement.style.display = 'block'
      } else {
        moreOptionsElement.style.display = 'none'
      }
    }
  }
  const addComponent = (e:any) =>{
    if(e.target.value == 'globe') {
      (document.getElementById('globe-text') as HTMLElement).style.display = 'block'
    }
  }
  const updateAddComponentText = (e:any) => {
    let globeTextElement = (document.getElementById('globe-text') as HTMLInputElement);
    if(globeTextElement) {
      setFieldValue('components',{'globe':{text : globeTextElement.value}})
    }
  }
  return (
    <>

      <div>
        <div className='col-8 d-inline-block'>
          <Field as="select" name="type" className="form-select mb-1" onChange={(e: any) => { section.type = e.target.value; updateValidationSchema(section) }}>
            <option value="">Choose the challenge type</option>
            <optgroup label='Instructional Sections'>
              <option value="slide">Slide</option>
              <option value="simulation">Simulation</option>
              <option value="simulationgraph">Simulation (Graph)</option>
              <option value="mindmapslide">Mind Map</option>
            </optgroup>
            <optgroup label='Traditional Questions'>
              <option value="mcq">Multiple Choice Question (MCQ)</option>
              <option value="multiple">Multiple Choice Question with Multiple Answers</option>
              <option value="matrix">Matrix Match</option>
              <option value="blanks">Fill in the blanks</option>
              <option value="numerical">Numerical</option>
              <option value="short">Short Text</option>
              <option value="long">Long Text</option>
            </optgroup>
            <optgroup label='Interactive Questions'>
              <option value="pair">Pair Match</option>
              <option value="dragdrop">Drag and Drop</option>
              <option value="verticalsequence">Sequence (Vertical)</option>
              <option value="horizontalsequence">Sequence (Horizontal)</option>
              <option value="click">Click</option>
            </optgroup>
            <optgroup label='Image Based Questions'>
              <option value="imageselect">Image Select</option>
              <option value="imagedraw">Image Draw</option>
            </optgroup>
            <optgroup label='MindMap Based Questions'>
              <option value="mindmapquestion">Mind Map Question</option>
              <option value="mindmapactivity">Mind Map Activity</option>
            </optgroup>
            <optgroup label='Simple Games'>
              <option value="crossword">Crossword</option>
              <option value="7lives">7 Lives Game</option>
            </optgroup>
          </Field>
        </div>
        <div className='col-4 d-inline-block'>
          <button type='button' onClick={() => { moreOptions() }} className='btn btn-primary ms-2 float-end p-2'>Toggle More Options</button>
        </div>
      </div>
      <div id='more-options' className='border border-dark shadow p-2 rounded' style={{ display: 'none' }}>
        <div><label className='form-label fw-bolder text-dark fs-6'>Add/Edit Components</label></div>
        <select id='add-component' onChange={(e)=>{addComponent(e)}}>
          <option>Select</option>
          <option value={'globe'}>Globe</option>
        </select>
        <input type='text' id='globe-text' style={{display:'none'}} onChange={(e)=>{updateAddComponentText(e)}}></input>
        <div><label className='form-label fw-bolder text-dark fs-6'>More Options</label></div>
        <div>
          <div className='d-inline-block me-2 row align-top mt-4'>
            <label className='form-label fw-bolder text-dark fs-6'>Points</label>
          </div>
          <div className='d-inline-block text-center'><Field type='number' name='positivePoints' className='form-control d-inline mb-1' style={{ width: "75px", marginLeft: "5px" }} /><br />
            <strong style={{ fontSize: '10px' }}>Positive</strong>
          </div>
          <div className='d-inline-block text-center'><Field type='number' name='negativePoints' className='form-control d-inline mb-1' style={{ width: "75px", marginLeft: "5px" }} /><br />
            <strong style={{ fontSize: '10px' }}>Negative</strong>
          </div>
        </div>
        {ErrorDisplay('type', errors)}
        <div>
          <strong>Tags -</strong>
          {section?.tags?.map((tag: string) => {
            return <div className="btn-group m-1" role="group" aria-label="Button group with cancel/delete options">
              <button type='button' className='p-2 btn btn-dark'>{tag}</button>
              <button type="button" className="btn btn-danger p-2" onClick={() => { removeTags(tag) }}>X</button>
            </div>
          })}
          {/**topicList('JEE-NEET', true)?.map((topic: string) => { return { value: topic, label: topic } }) */}
          <Select value={selectedOption} name='topicList' id='topicList' options={[{value: 'competency', label: 'competency'}]} isMulti={true} onChange={addTags} />
        </div>
        <div>
          <div>
            <strong>Difficulty -</strong>
            <button type='button' className={'btn p-2 m-1 btn-' + (section.difficultyLevel === 1 ? 'success' : 'primary')} onClick={() => { updateDifficultyLevel(1) }}>1</button>
            <button type='button' className={'btn p-2 m-1 btn-' + (section.difficultyLevel === 2 ? 'success' : 'primary')} onClick={() => { updateDifficultyLevel(2) }}>2</button>
            <button type='button' className={'btn p-2 m-1 btn-' + (section.difficultyLevel === 3 ? 'success' : 'primary')} onClick={() => { updateDifficultyLevel(3) }}>3</button>
            <button type='button' className={'btn p-2 m-1 btn-' + (section.difficultyLevel === 4 ? 'success' : 'primary')} onClick={() => { updateDifficultyLevel(4) }}>4</button>
            <button type='button' className={'btn p-2 m-1 btn-' + (section.difficultyLevel === 5 ? 'success' : 'primary')} onClick={() => { updateDifficultyLevel(5) }}>5</button>
          </div>
        </div>
        <button type='button' className='btn btn-primary p-2' onClick={() => { fixErrors() }}>Fix Errors</button>
      </div>
      {section.type === 'mcq' && <>
        <div>
          <label className='form-label fw-bolder text-dark fs-6'>Question</label>
          <button type='button' className='btn btn-primary p-2 ms-2' onClick={() => { addAutoOptions('ar') }}>A/R</button>
          <button type='button' className='btn btn-primary p-2 ms-2' onClick={() => { addAutoOptions('tf') }}>T/F</button>
        </div>
        <div>
          {TextEditor('secondary', section.text ? section.text : '', (section._id || 'undefined'), 0, updateText)}
        </div>
        <div><label className='form-label fw-bolder text-dark fs-6'>Options</label></div>
        {section?.options?.map((option: any, index: number) => {
          return (
            <div className='row mb-1 justify-content-center align-items-center' key={index}>
              <div className='col-1 text-center mt-3' >
                <Field type='radio' name='answer' value={typeof section.answer === 'number' ? index : index.toString()} style={{ height: "15px", width: "15px" }} />
              </div>
              <div className='col-10 mt-4'>
                {TextEditor('secondary', option, (section._id || 'undefined'), index, updateOptions)}
              </div>
              <div className='col-1'>
                <button type='button' className='btn btn-danger pt-1 pb-1' onClick={() => { deleteOptions(index) }}>
                  <i className="fa fa-close p-0"></i><br />
                </button>
              </div>
            </div>
          )
        })}
        {ErrorDisplay('options', errors)}
        {ErrorDisplay('answer', errors)}
        <button type='button' className='btn btn-primary' onClick={() => { addOptions() }}>Add Option</button>
      </>}
      {section.type === 'multiple' && <>
        <div><label className='form-label fw-bolder text-dark fs-6'>Question</label></div>
        <div>
          {TextEditor('secondary', section.text ? section.text : '', (section._id || 'undefined'), 0, updateText)}
        </div>
        <div><label className='form-label fw-bolder text-dark fs-6'>Options</label></div>
        {section?.options?.map((option: any, index: number) => {
          return (
            <div className='row mb-1 justify-content-center align-items-center' key={index}>
              <div className='col-1 text-center mt-3' >
                <Field type='checkbox' name='answer' value={typeof section.answer === 'number' ? index : index.toString()} style={{ height: "15px", width: "15px" }} />
              </div>
              <div className='col-10 mt-4'>
                {TextEditor('secondary', option, (section._id || 'undefined'), index, updateOptions)}
              </div>
              <div className='col-1'>
                <button type='button' className='btn btn-danger pt-1 pb-1' onClick={() => { deleteOptions(index) }}>
                  <i className="fa fa-close p-0"></i><br />
                </button>
              </div>
            </div>
          )
        })}
        {ErrorDisplay('options', errors)}
        {ErrorDisplay('answer', errors)}
        <button type='button' className='btn btn-primary' onClick={() => { addOptions() }}>Add Option</button>
      </>}
      {(section.type === 'slide' || section.type === 'simulation') && <>
        <div><label className='form-label fw-bolder text-dark fs-6'>Slide Content</label></div>
        <div>
          {TextEditor('secondary', section.text ? section.text : '', (section._id || 'undefined'), 0, updateText)}
        </div>
      </>
      }
      {section.type === 'numerical' && <>
        <div><label className='form-label fw-bolder text-dark fs-6'>Question</label></div>
        <div>
          {TextEditor('secondary', section.text ? section.text : '', (section._id || 'undefined'), 0, updateText)}
        </div>
        <div><label className='form-label fw-bolder text-dark fs-6'>Answer</label></div>
        <Field type='text' className='form-control form-control-lg mb-1'
          name='answer' placeholder='Answer' />
        {ErrorDisplay('answer', errors)}
      </>}
      {section.type === 'short' && <>
        <div><label className='form-label fw-bolder text-dark fs-6'>Question</label></div>
        <div>
          {TextEditor('secondary', section.text ? section.text : '', (section._id || 'undefined'), 0, updateText)}
        </div>
        <div><label className='form-label fw-bolder text-dark fs-6'>Possible Answers</label></div>
        {section?.options?.map((option: any, index: number) => {
          return (
            <div className='row mb-1 justify-content-center align-items-center' key={index}>
              <div className='col-11 mt-4'>
                {TextEditor('secondary', option, (section._id || 'undefined'), index, updateOptions)}
              </div>
              <div className='col-1'>
                <button type='button' className='btn btn-danger pt-1 pb-1' onClick={() => { deleteOptions(index) }}>
                  <i className="fa fa-close p-0"></i><br />
                </button>
              </div>
            </div>
          )
        })}
        {ErrorDisplay('options', errors)}
        {ErrorDisplay('answer', errors)}
        <button type='button' className='btn btn-primary' onClick={() => { addOptions() }}>Add Possible Answers</button>
      </>}
      {(section.type === 'pair' || section.type === 'horizontalsequence' || section.type === 'verticalsequence') && <>
        {section.type === 'pair' && <div><label className='form-label fw-bolder text-dark fs-6'>Write pairs in order</label></div>}
        {section.type !== 'pair' && <div><label className='form-label fw-bolder text-dark fs-6'>Write sequence in order</label></div>}
        <div><label className='form-label fw-bolder text-dark fs-6'>Question</label></div>
        <div>
          {TextEditor('secondary', section.text ? section.text : '', (section._id || 'undefined'), 0, updateText)}
        </div>
        <div><label className='form-label fw-bolder text-dark fs-6'>Sequence</label></div>
        {section?.options?.map((option: any, index: number) => {
          return (
            <div className='row mb-1 justify-content-center align-items-center' key={index}>
              <div className='col-11 mt-4'>
                {TextEditor('secondary', option, (section._id || 'undefined'), index, updateOptions)}
              </div>
              <div className='col-1'>
                <button type='button' className='btn btn-danger pt-1 pb-1' onClick={() => { deleteOptions(index) }}>
                  <i className="fa fa-close p-0"></i><br />
                </button>
              </div>
            </div>
          )
        })}
        {ErrorDisplay('options', errors)}
        {ErrorDisplay('answer', errors)}
        <button type='button' className='btn btn-primary' onClick={() => { addOptions() }}>Add Possible Answers</button>
      </>}
      {section.type === 'simulationgraph' && <>
        <SimulationGraph
          section={section}
          updateText={updateText}
        />
      </>
      }
      {section.type === 'imageselect' && <>
        <AnnotatableImage
          section={section}
          updateOptions={updateOptionsBulk}
          updateAnswer={updateAnswer}
          updateText={updateText}
        />
        {ErrorDisplay('answer', errors)}
      </>
      }
      {section.type === 'imagedraw' && <>
      </>
      }
      {section.type === '7lives' && <>
        <div><label className='form-label fw-bolder text-dark fs-6'>Word</label></div>
        <div>
          {TextEditor('secondary', section.text ? section.text : '', (section._id || 'undefined'), 0, updateText)}
        </div>
        <div><label className='form-label fw-bolder text-dark fs-6'>Clues</label></div>
        {section?.options?.map((option: any, index: number) => {
          return (
            <div className='row mb-1 justify-content-center align-items-center' key={index}>
              <div className='col-11 mt-4'>
                {TextEditor('secondary', option, (section._id || 'undefined'), index, updateOptions)}
              </div>
              <div className='col-1'>
                <button type='button' className='btn btn-danger pt-1 pb-1' onClick={() => { deleteOptions(index) }}>
                  <i className="fa fa-close p-0"></i><br />
                </button>
              </div>
            </div>
          )
        })}
        {ErrorDisplay('options', errors)}
        {ErrorDisplay('answer', errors)}
        <button type='button' className='btn btn-primary' onClick={() => { addOptions() }}>Add a Clue</button>
      </>}
      {(section.type === 'mindmapquestion' || section.type === 'mindmapslide') && <>
        <div><label className='form-label fw-bolder text-dark fs-6'>Question</label></div>
        <div>
          {TextEditor('secondary', section.text ? section.text : '', (section._id || 'undefined'), 0, updateText)}
        </div>
        <MindMap section={section} setFieldValue={setFieldValue} />
      </>}
      {(section.type === 'mindmapactivity') && <>
        <div><label className='form-label fw-bolder text-dark fs-6'>Question</label></div>
        <div>
          {TextEditor('secondary', section.text ? section.text : '', (section._id || 'undefined'), 0, updateText)}
        </div>
      </>}
      {section.type === 'long' && <>
        <div><label className='form-label fw-bolder text-dark fs-6'>Question</label></div>
        <div>
          {TextEditor('secondary', section.text ? section.text : '', (section._id || 'undefined'), 0, updateText)}
        </div>
        <div><label className='form-label fw-bolder text-dark fs-6'>Answer</label></div>
        {TextEditor('secondary', section.answer ? section.answer : '', (section._id || 'undefined'), 0, updateAnswer)}
        {ErrorDisplay('answer', errors)}
      </>}
      {(section.type === 'blanks' || section.type === 'blanks-any') && <>
        <div><label className='form-label fw-bolder text-dark fs-6'>Question</label></div>
        <div>
          {TextEditor('secondary', section.text ? section.text : '', (section._id || 'undefined'), 0, updateText)}
        </div>
        <div><label className='form-label fw-bolder text-dark fs-6'>Possible Answers</label></div>
        {section?.options?.map((option: any, index: number) => {
          return (
            <div className='row mb-1 justify-content-center align-items-center' key={index}>
              <div className='col-10 mt-4'>
                {TextEditor('secondary', option, (section._id || 'undefined'), index, updateOptions)}
              </div>
              <div className='col-1'>
                <button type='button' className='btn btn-danger pt-1 pb-1' onClick={() => { deleteOptions(index) }}>
                  <i className="fa fa-close p-0"></i><br />
                </button>
              </div>
            </div>
          )
        })}
        {ErrorDisplay('options', errors)}
        {ErrorDisplay('answer', errors)}
        <button type='button' className='btn btn-primary' onClick={() => { addOptions() }}>Add Possible Answers</button>
      </>}
      {section.type === 'click' && <>
        <div><label className='form-label fw-bolder text-dark fs-6'>Question</label></div>
        <div>
          {TextEditor('secondary', section.text ? section.text : '', (section._id || 'undefined'), 0, updateText)}
        </div>
      </>}
      {(section.type === 'matrix' || section.type === 'dragdrop') && <>
        <div><label className='form-label fw-bolder text-dark fs-6'>Question</label></div>
        <div>
          {TextEditor('secondary', section.text ? section.text : '', (section._id || 'undefined'), 0, updateText)}
        </div>
        <div><label className='form-label fw-bolder text-dark fs-6'>Options</label></div>
        {section?.options?.map((option: any, index: number) => {
          return (
            <div className='row mb-1 justify-content-center align-items-center' key={index}>
              <div className='col-1 text-end'>{(index + 1)}.</div>
              <div className='col-4 mt-4'>
                {TextEditor('secondary', option[0], (section._id || 'undefined'), index, updateOptions, 0)}
              </div>
              <div className='col-1 text-end'>{String.fromCharCode(index + 65).toUpperCase()}.</div>
              <div className='col-4 mt-4'>
                {TextEditor('secondary', option[1], (section._id || 'undefined'), index, updateOptions, 1)}
              </div>
              <div className='col-1'>
                <button type='button' className='btn btn-danger pt-1 pb-1' onClick={() => { deleteOptions(index) }}>
                  <i className="fa fa-close p-0"></i><br />
                </button>
              </div>
            </div>
          )
        })}
        {ErrorDisplay('options', errors)}
        <button type='button' className='btn btn-primary' onClick={() => { addOptionsMatrix() }}>Add Option</button>
        <div><label className='form-label fw-bolder text-dark fs-6'>Answer</label></div>
        <table>
          <tr><td></td>
            {section?.options?.map((option: any, index: number) => {
              return <>{option[1] && option[1].trim() !== '' && <td className='text-center'><b>{String.fromCharCode(index + 65).toUpperCase()}</b></td>}</>
            })}
          </tr>
          {section?.options?.map((option: any, index: number) => {
            return (
              <>
                {option[0] && option[0].trim() !== '' && <tr><td className='m-2'><b>{(index + 1)}.</b></td>
                  {section?.options?.map((option: any, index1: number) => {
                    return <>{option[1] && option[1].trim() !== '' && <td><Field className='m-2' type='checkbox' name='answer' value={index.toString() + '-' + index1.toString()} style={{ height: "15px", width: "15px" }} /></td>}</>
                  })}
                </tr>}
              </>
            )
          })}
        </table>
        {ErrorDisplay('answer', errors)}
      </>}
      {section.type === 'crossword' && <>
        <div><label className='form-label fw-bolder text-dark fs-6'>Words</label></div>
        <div className='row mb-1 justify-content-center align-items-center' >
          <div className='col-4 mt-4'>Word</div><div className='col-7 mt-4'>Clue</div>
        </div>
        {section?.options?.map((option: any, index: number) => {
          return (
            <div className='row mb-1 justify-content-center align-items-center' key={index}>
              <div className='col-4 mt-4'>
                {TextEditor('secondary', option[0], (section._id || 'undefined'), index, updateOptions, 0)}
              </div>
              <div className='col-7 mt-4'>
                {TextEditor('secondary', option[1], (section._id || 'undefined'), index, updateOptions, 1)}
              </div>
              <div className='col-1'>
                <button type='button' className='btn btn-danger pt-1 pb-1' onClick={() => { deleteOptions(index) }}>
                  <i className="fa fa-close p-0"></i><br />
                </button>
              </div>
            </div>
          )
        })}
        <button type='button' className='btn btn-primary' onClick={() => { addOptionsMatrix() }}>Add a Word</button>
        {ErrorDisplay('options', errors)}
      </>}
      <div className='mt-2'>
        <div><label className='form-label fw-bolder text-dark fs-6'>Explanation</label></div>
        {TextEditor('secondary', section.explanation ? section.explanation : '', (section._id || 'undefined'), 0, updateExplanation)}
      </div>
    </>
  )
}