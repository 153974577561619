import { FC, useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react';
import { DisplayLoader } from '../../../utils/loader';
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyByPMDxjiibLupiXvlbubCSlsabPFurpXA",
  authDomain: "olearno-4b1fb.firebaseapp.com",
  databaseURL: "https://olearno-4b1fb-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "olearno-4b1fb",
  storageBucket: "olearno-4b1fb.appspot.com",
  messagingSenderId: "990749151543",
  appId: "1:990749151543:web:8652bce69adc94c82acab2",
  measurementId: "G-PY2RTFHBE3"
};
//rdi6vb7v32p05f6s3tw2rcbcb744tomp2v5xvdop957bei7k,za8vhqb4s61fii8nwq70glf571i681423kh4vukokh1yxi37,m4nr26s01a3njqjgrgss9r3niezcz9yr7cqal8zluc91odje,2yf4p8k2k75teibzrdl66jr8i5lgy3l2qp55osf6pp29gf5f
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export const TextEditor = (type: string, text: string, sectionId: string, index: any, updateOptions?: Function, index2?: any) => {
  function handleEditorChange(value: string, editor: any) {
    if (updateOptions) updateOptions(index, value, index2);
  }

  return (<>
    <div className={'pt-1 rounded bg-' + type} key={index} style={{ paddingLeft: "5px" }}>
      <Editor apiKey='rdi6vb7v32p05f6s3tw2rcbcb744tomp2v5xvdop957bei7k'
        value={text}
        onEditorChange={handleEditorChange}
        init={{
          menubar: false, inline: true,
          plugins: [
            'charmap', 'code', 'image'
          ],
          toolbar: [
            'bold italic underline | fontselect fontsizeselect | forecolor backcolor | image code charmap',
          ],
          automatic_uploads: true,
          file_picker_types: 'image',
          file_picker_callback: function (cb, value, meta) {
            var input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');

            input.onchange = function () {
              if (!input || !input.files) return;
              var file = input.files[0];
              const storageRef = ref(storage, 'engage/sections/' + sectionId + '/' + file.name);
              const uploadTask = uploadBytesResumable(storageRef, file);
              DisplayLoader(true, '')
              uploadTask.on('state_changed',
                (snapshot) => {
                  const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  console.log('Upload is ' + progress + '% done');
                  switch (snapshot.state) {
                    case 'paused':
                      console.log('Upload is paused');
                      break;
                    case 'running':
                      console.log('Upload is running');
                      break;
                  }
                },
                (error) => {
                  // Handle unsuccessful uploads
                },
                () => {
                  getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    DisplayLoader(false, '')
                    cb(downloadURL, {
                      title: file.name
                    });
                  });
                }
              );

            };

            input.click();
          },
          toolbar_mode: 'scrolling',
          valid_elements: '*[*]',
          powerpaste_word_import: 'clean',
          powerpaste_html_import: 'clean',
        }} />
    </div>
  </>)
}