import * as Yup from 'yup'
export type Section = {
  text?: string
  group?: any
  components?: any
  explanation?: string
  points?: string
  options?: any
  jumbledOptions?: any
  answer?: any
  type?: string,
  heading?: string
  tags?: any
  source?: string
  reviewStatus?: number
  difficultyLevel?: number
  _id?: string
}
export const sectionInit: Section = {
  text: '',
  points: '',
  options: '',
  answer: '',
  type: '',
  heading: '',
}
export function ValidationSchema(section: Section, view?: boolean, onlyHeading?: boolean) {
  let validationObject: any = {}
  console.log("valid")
  if (!view) {
    validationObject = {
      heading: Yup.string().required().label('Challenge Name'),
    }
    if (section.heading) {
      validationObject = {
        ...validationObject, ...{
          type: Yup.string().required().label('Challenge Type'),
          //positivePoints: Yup.number().required().moreThan(0,'').label('Points'),
          //negativePoints: Yup.number().required().lessThan(1,'').label('Points')
        }
      }
    }
    if (section.type === 'mcq') {
      let validYup = Yup.array().of(Yup.string().required("Options cannot be empty")).min(1, "Atleast one option is required").required("Atleast one option is required")
      validationObject['options'] = validYup;
      validationObject['answer'] = Yup.mixed().required("Select the radio button in front of the right answer")
    }
    if (section.type === 'imageselect') {
      validationObject['answer'] = Yup.mixed().required("Select the correct answer")
    }
  } else {
    if (section.type === 'mcq') {
      validationObject['answer'] = Yup.mixed().required("Select the radio button in front of the right answer")
    } else if (section.type && section.type != '7lives' && section.type != 'verticalsequence' && section.type != 'horizontalsequence') {
      validationObject['answer'] = Yup.mixed().required("You have not answered yet.")
    }
  }
  return Yup.object(validationObject);
}