/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { VerifyEmail } from './components/VerifyEmail'
import { VerifyEmailLink } from './components/VerifyEmailLink'
import { ResetPassword } from './components/ResetPassword'

export const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      }}
    >
      <div id="loader-div" style={{display: "none"}}>
        <div className="d-flex align-items-center justify-content-center" style={{ background: "#ffffff89", position: "fixed", width: "100%", left: "0px", top: "0px", height: "100%", zIndex: "10" }}>
          <div className="spinner-border text-primary" role="status">
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center text-center" style={{ background: "#ffffff00", position: "fixed", width: "100%", left: "0px", top: "50px", height: "100%", zIndex: "11" }}>
          <div id="loader-text" className="text-muted fs-6 fw-semibold" style={{ width: "100%" }}>Loading...</div>
        </div>
      </div>
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-5 pb-lg-20'>
        {/* begin::Logo */}
        <a href='#' className='mb-12'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/default.jpg')} className='h-45px' />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-body rounded shadow-sm p-5 p-lg-10 mx-auto'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <a href='#' className='text-muted text-hover-primary px-2'>
            About
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact Us
          </a>
        </div>
      </div>
      {/* end::Footer */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='verify-email' element={<VerifyEmail />} />
      <Route path='verify-account/:token' element={<VerifyEmailLink />} />
      <Route path='reset-password/:token' element={<ResetPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
