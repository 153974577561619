/* eslint-disable jsx-a11y/anchor-is-valid */
import { Section } from '../../../models/Section'
import { Field, FormikErrors } from 'formik'
import { ErrorDisplay } from '../common/ErrorDisplay'
import Latex from "react-latex-next";
import "katex/dist/katex.min.css";
import clg from 'crossword-layout-generator';
import CWG from 'cwg';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import { SevenLives } from './displayquestiontypes/7lives';
import SequenceHorizontal from './displayquestiontypes/SequenceHorizontal';
import SequenceVertical from './displayquestiontypes/SequenceVertical';
import DragDrop from './displayquestiontypes/DragAndDrop';
import { CrossWord } from './displayquestiontypes/Crossword';
import { systemSort } from '../../../utils/systemSort';
import AnnotatableImageView from './displayquestiontypes/ImageAnnotationView';
import SimulationGraph from './SimulationGraph';
import MindMapQuestion from './displayquestiontypes/MindMapFill';

//import { CrosswordSection } from './displayquestiontypes/Crossword';
export const ViewSection = (section: Section, disabled: boolean, scale: number, errors: FormikErrors<Section>, values: any, setFieldValue: Function, attempt?: any, view?: boolean, submitAnswer?: Function, setCurrentSectionNoFromId?: Function) => {
  const parseQuestion = (question: string, type: string) => {
    if (type == 'blanks' || type == 'blanks-any') {
      return question.replace(/#blank([0-9])#/g, '<button type="button" class="btn btn-info ms-2 me-2 mb-2" style="min-width: 100px" name="blanksQuestion$1" id="blank$1" disabled></button>');
    }
    if (type == 'click') {
      let q = question.replace(/<ca>|&lt;ca&gt;|<wa>|&lt;wa&gt;|<ba>|&lt;ba&gt;/g, '<button type="button" class="btn btn-info" onClick={function(e){blankClick(e)}}>').replace(/<\/ca>|&lt;\/ca&gt;|<\/wa>|&lt;\/wa&gt;|<\/ba>|&lt;\/ba&gt;/g, '</button>');
      console.log(q);
      return q;
    }
    return '';
  }
  const blankClick = (e: React.MouseEvent<HTMLButtonElement>, index: number) => {
    console.log("blankclick")
    if (!values.answer || values.answer.indexOf(systemSort(section.options)[index]) == -1) {
      const addIndex = values['answer'] ? (values['answer'].indexOf('') != -1 ? values['answer'].indexOf('') : values['answer'].length) : 0;
      if (document.getElementsByName('blanksQuestion' + (addIndex + 1)).length == 0) return;
      let elements = document.getElementsByName('blanksQuestion' + (addIndex + 1));
      for (let i = 0; i < elements.length; i++) {
        (elements[i] as HTMLButtonElement).innerHTML = e.currentTarget.innerHTML;
      }
      let updatedAnswer = values.answer ? values.answer : [];
      updatedAnswer[addIndex] = systemSort(section.options)[index];
      setFieldValue('answer', updatedAnswer);
    } else {
      let updatedAnswer = values.answer;
      //let removeAtIndex = -1;
      updatedAnswer = updatedAnswer.map((value: string, bIndex: number) => {
        if (value == systemSort([...section.options])[index]) {
          document.getElementsByName('blanksQuestion' + (bIndex + 1)).forEach((element: HTMLElement) => {
            if (element.textContent == e.currentTarget.textContent) {
              element.innerHTML = '';
            }
          });
          return ''
        } else { return value }
      })
      /*
      if (removeAtIndex != -1) {
        document.getElementsByName('blanksQuestion' + (removeAtIndex + 1)).forEach((element: HTMLElement) => {
          if (element.textContent == e.currentTarget.textContent) {
            element.innerHTML = '';
          }
        })
      }
      */
      setFieldValue('answer', updatedAnswer);
    }
  }
  const parseBlankAnswer = (text: string) => {
    const answerLength = text.match(/#blank([0-9])#/g)?.length || 0;
    let answer = text;
    for (let i = 0; i < answerLength; i++) {
      answer = answer.replaceAll("#blank" + (i + 1) + "#", '<button type="button" class="btn btn-info ms-2 me-2 mb-2" style="min-width: 100px" name="blanksQuestion' + (i + 1) + '" disabled>' + section.options[i] + '</button>')
    }
    return answer;
  }
  const submitCrossWord = async () => {
    if (submitAnswer) {
      submitAnswer(values);
    }
  }
  const pairClick = (e: React.MouseEvent<HTMLButtonElement>, option: string) => {
    if (setCurrentSectionNoFromId) {
      setCurrentSectionNoFromId(section._id);
    }
    e.currentTarget.disabled = true;
    let updatedAnswer = values.answer ? values.answer : [];
    updatedAnswer.push(option);
    if (updatedAnswer.length > 0 && updatedAnswer.length % 2 == 0) {
      let lastOptions = [updatedAnswer[updatedAnswer.length - 1], updatedAnswer[updatedAnswer.length - 2]]
      let sumOfIndexes = section.options.indexOf(lastOptions[0]) + section.options.indexOf(lastOptions[1]);
      let diffOfIndexes = Math.abs(section.options.indexOf(lastOptions[0]) - section.options.indexOf(lastOptions[1]));
      if ((sumOfIndexes - 1) % 4 == 0 && diffOfIndexes == 1) {
        document.getElementById('pair' + lastOptions[0])?.classList.remove('btn-info')
        document.getElementById('pair' + lastOptions[1])?.classList.remove('btn-info')
        document.getElementById('pair' + lastOptions[0])?.classList.add('btn-success')
        document.getElementById('pair' + lastOptions[1])?.classList.add('btn-success')
        if (updatedAnswer.length == section.options.length) {
          if (submitAnswer) {
            submitAnswer(values);
          }
        }
      } else {
        setFieldValue('negativePoints', values.negativePoints ? (values.negativePoints + 1) : 1);
        document.getElementById('pair' + lastOptions[0])?.classList.remove('btn-info')
        document.getElementById('pair' + lastOptions[1])?.classList.remove('btn-info')
        document.getElementById('pair' + lastOptions[0])?.classList.add('btn-danger')
        document.getElementById('pair' + lastOptions[1])?.classList.add('btn-danger')
        updatedAnswer.pop();
        updatedAnswer.pop();
        setTimeout(() => {
          document.getElementById('pair' + lastOptions[0])?.classList.remove('btn-danger')
          document.getElementById('pair' + lastOptions[1])?.classList.remove('btn-danger')
          document.getElementById('pair' + lastOptions[0])?.classList.add('btn-info')
          document.getElementById('pair' + lastOptions[1])?.classList.add('btn-info')
          if (document.getElementById('pair' + lastOptions[0])) {
            (document.getElementById('pair' + lastOptions[0]) as HTMLButtonElement).disabled = false;
          }
          if (document.getElementById('pair' + lastOptions[1])) {
            (document.getElementById('pair' + lastOptions[1]) as HTMLButtonElement).disabled = false;
          }
        }, 1000)
      }
    }
    console.log(updatedAnswer);
    setFieldValue('answer', updatedAnswer);
  }
  const isMatch = (index: any, category: any, answer: any, options: any) => {
    let ans = answer.filter((a: any) => { return a.split('-')[0] == index })[0]
    if (ans) {
      let ansRight = ans.split('-')[1]
      return category == options[ansRight][1]
    }
  };
  const parseCrossword = (options: string[][]) => {
    let result = clg.generateLayout(options.map((option: string[]) => { return { clue: option[1].replace(/<[^>]*>?/gm, ''), answer: option[0].replace(/<[^>]*>?/gm, '').toUpperCase() } })).result;
    //let result = clg.generateLayout([{ "clue": "that which is established as a rule or model by authority, custom, or general consent", "answer": "standard" }, { "clue": "a machine that computes", "answer": "computer" }, { "clue": "the collective designation of items for a particular purpose", "answer": "equipment" }, { "clue": "an opening or entrance to an inclosed place", "answer": "port" }, { "clue": "a point where two things can connect and interact", "answer": "interface" }]).result
    /*
    const result1 = CWG(options.map((option: string[]) => { return option[0].replace(/<[^>]*>?/gm, '') }))
    */
    //console.log(result)
    let crosswordData: any = {
      'across': {},
      'down': {},
    }
    console.log(result)
    let crosswordIndexInfo: any = {}
    result.forEach((word: any, index: number) => {
      if (!crosswordIndexInfo[word.position]) {
        crosswordIndexInfo[word.position] = {};
      }
      if (word.orientation == 'across') {
        crosswordData['across'][word.position] = { clue: word.clue, answer: word.answer, row: word.starty - 1, col: word.startx - 1 }
        //crosswordData['across'][crosswordIndexInfo[word.position]] = { clue: word.clue, answer: options.filter((opt: string[]) => { return opt[0].replace(/<[^>]*>?/gm, '') == word.wordStr })[0][1].replace(/<[^>]*>?/gm, ''), row: word.xNum, col: word.yNum }
      } else {
        crosswordData['down'][word.position] = { clue: word.clue, answer: word.answer, row: word.starty - 1, col: word.startx - 1 }
      }
    })
    console.log(crosswordData)
    /*
    result.forEach((clue: any) => {
      if (clue.orientation == 'across') {
        crosswordData['across'][clue.position] = { clue: clue.clue.replace(/<[^>]*>?/gm, ''), answer: clue.answer.replace(/<[^>]*>?/gm, ''), row: clue.startx - 1, col: clue.starty - 1 }
      } else {
        crosswordData['down'][clue.position] = { clue: clue.clue.replace(/<[^>]*>?/gm, ''), answer: clue.answer.replace(/<[^>]*>?/gm, ''), row: clue.startx - 1, col: clue.starty - 1 }
      }
    })
    */
    return crosswordData;
  }
  return (
    <div className='text-dark' style={{ width: "100%", marginTop: "10px" }}>
      {/*
        section.components && section.components.globe && <>
        Hello
          <GlobeComponent />
        </>
        */
      }
      {
        section?.group?.text && <h6><Latex>{section.group?.text || ''}</Latex></h6>
      }
      {section.type == 'slide' && <>
        <div>
          <Latex>{section.text ? section.text : ''}</Latex>
        </div>
      </>
      }
      {section.type == 'simulation' && <>
        <div>
          <Latex>{section.text ? section.text : ''}</Latex>
        </div>
      </>
      }
      {(section.type == 'mcq' || section.type == 'comprehension') && <>
        <div className='text-dark animate__animated animate__bounceInUp'>Select the correct answer</div>
        <div className='animate__animated animate__bounceInUp'>
          <h4 style={{ fontWeight: 'normal' }}><Latex>{section.text ? section.text : ''}</Latex></h4>
        </div>
        <div><label className='form-label fw-bolder text-dark fs-6 animate__animated animate__fadeIn' style={{ animationDelay: "0.4s" }}>Options</label></div>
        {section?.options?.map((option: any, index: number) => {
          return (
            <>
              <div className={'row m-1 justify-content-center align-bottom rounded animate__animated animate__bounceInRight ' + ((attempt && section.answer == index) ? 'border border-success' : ((attempt && attempt.answer == index && section.answer != index) ? (attempt.showCorrectAnswer == false ? 'border border-success' : 'border border-danger') : 'pt-4'))} key={index}
                style={{ animationDelay: (index * 0.05 + 0.5) + "s" }}>
                {(attempt && attempt && section.answer == index) && <span className='bg-success p-2'>Correct Answer</span>}
                {(attempt && attempt.answer == index && section.answer != index) && <span className={(attempt.showCorrectAnswer == false ? 'bg-success ' : 'bg-danger ') + 'p-2'}>{attempt.showCorrectAnswer == false ? 'Your Answer' : 'Your Wrong Answer'}</span>}
                <label className={'shadow p-2 rounded-bottom border pt-1 ' + (((!disabled && values && index == values.answer) && !(attempt && attempt.answer)) ? ' border-3 border-primary' : 'border-dark')}>
                  <div className='col-11 d-inline-block'><Latex>{option || ''}</Latex></div>
                  <div className='col-1 text-center d-inline-block' >
                    <Field type='radio' name='answer' value={(view ? '' : 'v') + index.toString()} style={{ height: "20px", width: "20px", opacity: '0' }} disabled={disabled} />
                  </div>
                </label>
              </div>
            </>
          )
        })}
        {ErrorDisplay('options', errors)}
      </>}
      {section.type == 'multiple' && <>
        <div className='text-dark animate__animated animate__bounceInUp'>Select ALL the correct answers. MORE THAN ONE answers might be correct</div>
        <div className='animate__animated animate__bounceInUp'>
          <h4 style={{ fontWeight: 'normal' }}><Latex>{section.text ? section.text : ''}</Latex></h4>
        </div>
        <div><label className='form-label fw-bolder text-dark fs-6 animate__animated animate__fadeIn' style={{ animationDelay: "0.4s" }}>Options (MULTIPLE)</label></div>
        {section?.options?.map((option: any, index: number) => {
          return (
            <>
              <div className={'row col-12 m-1 justify-content-center align-bottom rounded animate__animated animate__bounceInRight ' + ((attempt && section.answer && section.answer.indexOf(index.toString()) != -1) ? 'border border-success' : ((attempt && attempt.answer && attempt.answer.indexOf(index.toString()) != -1 && section.answer && section.answer.indexOf(index.toString()) == -1) ? (attempt.showCorrectAnswer == false ? 'border border-success' : 'border border-danger') : 'pt-2'))} key={index}
                style={{ animationDelay: (index * 0.05 + 0.5) + "s" }}>
                {(attempt && section.answer && section.answer.indexOf(index.toString()) != -1) && <span className='bg-success p-2'>Correct Answer</span>}
                {(attempt && attempt.answer && attempt.answer.indexOf(index.toString()) != -1 && section.answer && section.answer.indexOf(index.toString()) == -1) && <span className={(attempt.showCorrectAnswer == false ? 'bg-success ' : 'bg-danger ') + 'p-2'}>{attempt.showCorrectAnswer == false ? 'Your Answer' : 'Your Wrong Answer'}</span>}
                <label className={'shadow p-2 rounded-bottom border pt-1 ' + ((values.answer && values.answer.indexOf(index.toString()) != -1 && !(attempt && attempt.answer)) ? ' border-3 border-primary' : 'border-dark')}>
                  <div className='col-11 d-inline-block align-top pt-1'><Latex>{option || ''}</Latex></div>
                  <div className='col-1 text-center d-inline-block' >
                    <Field type='checkbox' name='answer' value={(view ? '' : 'v') + index.toString()} style={{ height: "20px", width: "20px", opacity: "0" }} disabled={disabled} />
                  </div>
                </label>
              </div>
            </>
          )
        })}
        {ErrorDisplay('options', errors)}
      </>}
      {section.type == 'numerical' && <>
        <div className='text-dark animate__animated animate__bounceInUp'>Enter the correct answer in the box. It can only be a number</div>
        <div className='animate__animated animate__bounceInUp'>
          <h4 style={{ fontWeight: 'normal' }}><Latex>{section.text ? section.text : ''}</Latex></h4>
        </div>
        <div><label className='form-label fw-bolder text-dark fs-6 animate__animated animate__fadeIn' style={{ animationDelay: "0.4s" }}>Answer</label></div>
        <Field type='text' className='form-control form-control-lg m-1 animate__animated animate__bounceInRight'
          name={(view ? '' : 'v') + 'answer'} placeholder='Answer' disabled={disabled} style={{ animationDelay: "0.5s" }} />
        {attempt && !attempt.correct && attempt.answer && <div className='row col-12 m-1 mt-2 justify-content-center align-bottom rounded border border-success'>
          <span className='bg-danger p-2 mb-2'>Correct Answer</span>
          <div><Latex>{section.answer || ''}</Latex></div>
        </div>}
      </>}
      {section.type == 'short' && <>
        <div className='text-dark animate__animated animate__bounceInUp'>Enter the correct answer in the box.</div>
        <div className='animate__animated animate__bounceInUp'>
          <h4 style={{ fontWeight: 'normal' }}><Latex>{section.text ? section.text : ''}</Latex></h4>
        </div>
        <div><label className='form-label fw-bolder text-dark fs-6 animate__animated animate__fadeIn' style={{ animationDelay: "0.4s" }}>Answer</label></div>
        <Field type='text' className='form-control form-control-lg m-1 animate__animated animate__bounceInRight'
          name={(view ? '' : 'v') + 'answer'} placeholder='Answer' disabled={disabled} style={{ animationDelay: "0.5s" }} />
        {attempt && attempt.correct == true && <div className={'row col-12 m-1 mt-2 justify-content-center align-bottom rounded border border-' + attempt.correct ? 'success' : 'danger'}>
          <span className={'p-2 mb-2 bg-' + attempt.correct ? 'success' : 'danger'}>Correct Answer is one of the following</span>
          <div>
            {attempt.answer}
          </div>
        </div>}
        {attempt && attempt.correct != null && <div className={'row col-12 m-1 mt-2 justify-content-center align-bottom rounded border border-' + (attempt.correct ? 'success' : 'danger')}>
          <span className={'p-2 mb-2 bg-' + (attempt.correct ? 'success' : 'danger')}>Correct Answer is one of the following</span>
          <div>
            {section.options?.map((option: string) => {
              return <div><Latex>{option || ''}</Latex></div>
            })}
          </div>
        </div>}
      </>}
      {section.type == 'long' && <>
        <div className='text-dark animate__animated animate__bounceInUp'>Enter the correct answer in the box.</div>
        <div className='animate__animated animate__bounceInUp'>
          <h4 style={{ fontWeight: 'normal' }}><Latex>{section.text ? section.text : ''}</Latex></h4>
        </div>
        <div><label className='form-label fw-bolder text-dark fs-6 animate__animated animate__fadeIn' style={{ animationDelay: "0.4s" }}>Answer</label></div>
        <Field component="textarea" rows="4" className='form-control form-control-lg mb-1 animate__animated animate__bounceInRight'
          name={(view ? '' : 'v') + 'answer'} placeholder='Answer' disabled={disabled} style={{ animationDelay: "0.5s" }} />
        {attempt && !attempt.correct && attempt.answer && <div className='row col-12 m-1 mt-2 justify-content-center align-bottom rounded border border-success'>
          <span className='bg-danger p-2 mb-2'>Correct Answer</span>
          <div><Latex>{section.answer ? section.answer : 'NA'}</Latex></div>
        </div>}
      </>}
      {(section.type == 'blanks' || section.type == 'blanks-any') && <>
        <div className='text-dark animate__animated animate__bounceInUp'>Tap the options to fill in the blank. Tap them again to undo the fill</div>
        <div className='animate__animated animate__bounceInUp'>
          <Latex>{parseQuestion(section.text ? section.text : '', section.type)}</Latex>
        </div>
        <div><label className='form-label fw-bolder text-dark fs-6 animate__animated animate__fadeIn' style={{ animationDelay: "0.4s" }}>Options</label></div>
        {systemSort([...section.options]).map((option: any, index: number) => {
          return (
            <>
              <button type='button' name='blanksOption' className='btn btn-info m-2 animate__animated animate__bounceInUp' onClick={function (e) { blankClick(e, index) }} disabled={disabled} style={{ animationDelay: (index * 0.05 + 0.5) + "s" }}>
                <Latex>{option || ''}</Latex>
              </button>
            </>
          )
        })}
        {ErrorDisplay('options', errors)}
        {attempt && attempt.correct == false && section.answer && <div className='row col-12 m-1 mt-2 justify-content-center align-bottom rounded border border-success'>
          <span className='bg-danger p-2 mb-2'>Correct Answer</span>
          <Latex>{section.answer || ''}</Latex>
        </div>}
        {attempt && attempt.correct == false && !section.answer && <div className='row col-12 m-1 mt-2 justify-content-center align-bottom rounded border border-success'>
          <span className='bg-danger p-2 mb-2'>Correct Answer</span>
          <Latex>{parseBlankAnswer(section.text || '')}</Latex>
        </div>}
      </>}
      {section.type == 'pair' && <div>
        <div className='text-dark animate__animated animate__bounceInUp'>Tap the pair of correct options consecutively</div>
        <div className='animate__animated animate__bounceInUp'>
          <h4 style={{ fontWeight: 'normal' }}><Latex>{section.text ? section.text : ''}</Latex></h4>
        </div>
        {section.options && systemSort([...section.options])?.map((option: string, index: number) => {
          return (<>
            <button type='button' id={'pair' + option} className='btn btn-info m-2 animate__animated animate__bounceInUp' onClick={function (e) { pairClick(e, option) }} disabled={disabled} style={{ animationDelay: (index * 0.05 + 0.5) + "s" }}>
              <Latex>{option || ''}</Latex>
            </button>
          </>)
        })}
        {attempt && attempt.correct && <div className='row col-12 m-1 mt-2 justify-content-center align-bottom rounded border border-success'>
          <span className='bg-success p-2 mb-2'>Correct Answer</span>
          {section.options.map((option: string, index: number) => {
            if (index % 2 == 1) {
              return <><Latex>{option || ''}</Latex><hr /></>
            } else {
              return <><Latex>{option || ''}</Latex></>
            }
          })}
        </div>}
      </div>}
      {section.type == 'click' && <><div>
        <></>
      </div></>}
      {section.type == 'matrix' && <>
        <div className='text-dark animate__animated animate__bounceInUp'>Match the options on the left to the one on the right in the matrix of checkboxes below</div>
        <h4 style={{ fontWeight: 'normal' }} className='animate__animated animate__bounceInUp'><Latex>{section.text ? section.text : ''}</Latex></h4>
        <div className='animate__animated animate__bounceInRight' style={{ animationDelay: "0.5s" }}>
          {section?.options?.map((option: any, index: number) => {
            return (
              <div className='row mb-1 justify-content-center align-items-center' key={index}>
                <div className='col-1 text-end'>{(option[0] && option[0] != '') && <>{(index + 1)}.</>}</div>
                <div className='col-5 '><Latex>{option[0] || ''}</Latex></div>
                <div className='col-1 text-end'>{(option[1] && option[1] != '') && <>{String.fromCharCode(index + 65).toUpperCase()}.</>}</div>
                <div className='col-5 '><Latex>{option[1] || ''}</Latex></div>
              </div>
            )
          })}
        </div>
        <div><label className='form-label fw-bolder text-dark fs-6'>Answer</label></div>
        <table className='animate__animated animate__bounceInRight' style={{ animationDelay: "0.65s" }}>
          <tr><td></td>
            {section?.options?.map((option: any, index: number) => {
              return <>{option[1] && option[1].trim() != '' && <td className='text-center'><b>{String.fromCharCode(index + 65).toUpperCase()}</b></td>}</>
            })}
          </tr>
          {section?.options?.map((option: any, index: number) => {
            return (
              <>
                {option[0] && option[0].trim() != '' && <tr><td className='m-2'><b>{(index + 1)}.</b></td>
                  {section?.options?.map((option: any, index1: number) => {
                    return <>{option[1] && option[1].trim() != '' && <td><Field className='m-2 rounded' type='checkbox' name='answer' value={(view ? '' : 'v') + index.toString() + '-' + index1.toString()} style={{ height: "25px", width: "25px" }} disabled={disabled} /></td>}</>
                  })}
                </tr>}
              </>
            )
          })}
        </table>
        {attempt && !attempt.correct && attempt.answer && <div className='row col-12 m-1 mt-2 justify-content-center align-bottom rounded border border-success'>
          <span className='bg-danger p-2 mb-2'>Correct Answer</span>
          <div>{section?.options?.map((option: any, index: number) => {
            return (
              <>
                {option[0] && option[0].trim() != '' && <tr><td className='m-2'><b>{(index + 1)}.</b></td>
                  {section?.options?.map((option: any, index1: number) => {
                    return <>{option[1] && option[1].trim() != '' && <td><input className='m-2' type='checkbox' name='answer' checked={section?.answer.indexOf(index.toString() + '-' + index1.toString()) != -1} value={(view ? '' : 'v') + index.toString() + '-' + index1.toString()} style={{ height: "15px", width: "15px" }} disabled={disabled} /></td>}</>
                  })}
                </tr>}
              </>
            )
          })}
          </div>
        </div>}
      </>}
      {section.type == 'simulationgraph' && <div className='animate__animated animate__bounceInUp'>
        <SimulationGraph section={section} />
      </div>
      }
      {section.type == 'imageselect' && <div className='animate__animated animate__bounceInUp'>
        <AnnotatableImageView
          section={section}
          disabled={disabled}
          values={values}
          attempt={attempt}
          setFieldValue={setFieldValue} />
      </div>}
      {section.type == '7lives' && <><div>
        {SevenLives(section.text?.replace(/(<([^>]+)>)/ig, '').toLowerCase() || '', section, disabled, values, attempt, submitAnswer)}
        {attempt && (attempt.correct == false) && <div className={'row col-12 m-1 mt-2 justify-content-center align-bottom rounded animate__animated animate__bounceInUp border border-' + (attempt.correct == true ? 'success' : 'danger')} style={{ animationDelay: '0.75s', pointerEvents: 'none' }}>
          <span className={'p-2 mb-2 bg-danger'}>Correct Answer</span>
          <Latex>{section.text || ''}</Latex>
        </div>}
      </div></>}
      {section.type == 'mindmapquestion' && <><div>
        <h4 style={{ fontWeight: 'normal' }}><Latex>{section.text ? section.text : ''}</Latex></h4>
        {(!attempt || attempt.correct == null) && <MindMapQuestion initialData={section.options} setFieldValue={setFieldValue} />}
        {(attempt && attempt.correct != null && !attempt.answer?.data) && <MindMapQuestion initialData={{ meta: { name: 'demo', author: 'olearno' }, format: 'node_tree', data: attempt.answer }} setFieldValue={setFieldValue} />}
        {attempt && (attempt.correct == false) && <div className={'row col-12 m-1 mt-2 justify-content-center align-bottom rounded animate__animated animate__bounceInUp border border-' + (attempt.correct == true ? 'success' : 'danger')} style={{ animationDelay: '0.75s', pointerEvents: 'none' }}>
          <span className={'p-2 mb-2 bg-danger'}>Correct Answer</span>
          <MindMapQuestion initialData={section.answer} setFieldValue={setFieldValue} />
        </div>}
      </div></>}
      {section.type == 'mindmapslide' && <><div>
        <h4 style={{ fontWeight: 'normal' }}><Latex>{section.text ? section.text : ''}</Latex></h4>
        <MindMapQuestion initialData={section.answer} setFieldValue={setFieldValue} />
      </div></>}
      {section.type == 'mindmapactivity' && <><div>
        <h4 style={{ fontWeight: 'normal' }}><Latex>{section.text ? section.text : ''}</Latex></h4>
        <MindMapQuestion initialData={{}} setFieldValue={setFieldValue} type={section.type} />
      </div></>}
      {section.type == 'crossword' && <><div>
        {<CrossWord crosswordData={parseCrossword(section.options)} submitCrossWord={submitCrossWord} answer={attempt?.correct} />}
      </div></>}
      {section.type == 'dragdrop' && <><div className='text-dark animate__animated animate__bounceInUp'>Drag the elements to the correct box</div><div className='animate__animated animate__bounceInUp'>
        <h4 style={{ fontWeight: 'normal' }}><Latex>{section.text ? section.text : ''}</Latex></h4>
      </div>
        <div className=' animate__animated animate__fadeIn ' style={{ animationDelay: '0.25s' }}>
          {(!attempt || (attempt.correct == null)) && <div>
            <DragDrop setFieldValue={setFieldValue} itemsIn={[...[
              { id: 'question', items: section.options.map((opt: string[]) => opt[0]).filter((opt: string) => { return opt != '' }).map((opt: string, index: number) => { return { id: index.toString(), name: opt, index: index } }) }
            ],
            ...section.options.map((opt: string[]) => opt[1]).filter((opt: string) => opt != '').map((opt: string) => { return { id: opt, items: [] } })]}></DragDrop>
          </div>}
          {attempt && attempt.correct == true && <div>{
            section.options.map((opt: string[]) => opt[0]).filter((opt: string) => { return opt != '' }).map((opt: string, index: number) => { return { id: index.toString(), name: opt, index: index } }).map((item: any) => {
              return <div className='m-2 p-2 rounded shadow d-inline-block'
                style={{
                  border: '1px solid #ccc',
                  padding: '8px',
                  marginBottom: '8px',
                  backgroundColor: 'white',
                }}
              >
                <Latex>{item.name || ''}</Latex>
              </div>
            })}</div>}
          {attempt && (attempt.correct == true || attempt.correct == false) && <div className={'row col-12 m-1 mt-2 justify-content-center align-bottom rounded'} style={{ pointerEvents: 'none' }}>
            <DragDrop setFieldValue={setFieldValue} itemsIn={[...[
              { id: 'question', items: [] }
            ],
            ...section.options.map((opt: string[]) => opt[1]).filter((opt: string) => opt != '').map((opt: string) => { return { id: opt, items: section.options.map((opt: string[]) => opt[0]).filter((opt1: string, index: number) => { return opt1 != '' && isMatch(index, opt, attempt.answer, section.options) }).map((opt: string, index: number) => { return { id: index.toString(), name: opt, index: index } }) } })]}></DragDrop>
          </div>}
          {attempt && (attempt.correct == false) && <div className={'row col-12 m-1 mt-2 justify-content-center align-bottom rounded animate__animated animate__bounceInUp border border-' + (attempt.correct == true ? 'success' : 'danger')} style={{ animationDelay: '0.75s', pointerEvents: 'none' }}>
            <span className={'p-2 mb-2 bg-' + (attempt.correct == true ? 'success' : 'danger')}>Correct Answer</span>
            <DragDrop setFieldValue={setFieldValue} itemsIn={[...[
              { id: 'question', items: [] }
            ],
            ...section.options.map((opt: string[]) => opt[1]).filter((opt: string) => opt != '').map((opt: string) => { return { id: opt, items: section.options.map((opt: string[]) => opt[0]).filter((opt1: string, index: number) => { return opt1 != '' && isMatch(index, opt, section.answer, section.options) }).map((opt: string, index: number) => { return { id: index.toString(), name: opt, index: index } }) } })]}></DragDrop>
          </div>}
        </div>
      </>}
      {section.type == 'horizontalsequence' && <><div>
        <div className='text-dark animate__animated animate__bounceInUp'>Arrange in correct order</div>
        <div className='animate__animated animate__bounceInUp'>
          <h4 style={{ fontWeight: 'normal' }}><Latex>{section.text ? section.text : ''}</Latex></h4>
        </div>
        <div className=' animate__animated animate__fadeIn ' style={{ animationDelay: '0.25s' }}>
          {(!attempt || !attempt.answer || attempt.correct == null) && <SequenceHorizontal setFieldValue={setFieldValue} itemsIn={[...section.options].sort((a: string, b: string) => {
            const randomTrueOrFalse = (a.length + Array.from(a + b).reduce((sum, char) => sum + char.charCodeAt(0) - 'A'.charCodeAt(0) + 1, 0)) % 2 == 1;
            return randomTrueOrFalse ? 1 : -1
          })?.map((option: string, index: number) => {
            return ({ id: index.toString(), name: option, index: index })
          })}></SequenceHorizontal>}
          {attempt && attempt.answer && (attempt.correct == true || attempt.correct == false) && <div style={{ pointerEvents: 'none' }}><SequenceHorizontal setFieldValue={setFieldValue} itemsIn={attempt.answer.map((option: string, index: number) => {
            return ({ id: index.toString(), name: option, index: index })
          })}></SequenceHorizontal></div>}
          {attempt && (attempt.correct == false) && <div className={'row col-12 m-1 mt-2 justify-content-center align-bottom rounded animate__animated animate__bounceInUp border border-' + (attempt.correct == true ? 'success' : 'danger')} style={{ animationDelay: '0.75s' }}>
            <span className={'p-2 mb-2 bg-' + (attempt.correct == true ? 'success' : 'danger')}>Correct Order</span>
            {section?.options?.map((option: any) => { return <span><Latex>{option || ''}</Latex></span> })}
          </div>}
        </div>
      </div></>}
      {section.type == 'verticalsequence' && <><div>
        <div className='text-dark animate__animated animate__bounceInUp'>Arrange in correct order</div>
        <div className='animate__animated animate__bounceInUp'>
          <h4 style={{ fontWeight: 'normal' }}><Latex>{section.text ? section.text : ''}</Latex></h4>
        </div>
        <div className=' animate__animated animate__fadeIn ' style={{ animationDelay: '0.25s' }}>
          {(!attempt || !attempt.answer || attempt.correct == null) && <SequenceVertical setFieldValue={setFieldValue} itemsIn={[...section.options].sort((a: string, b: string) => {
            const randomTrueOrFalse = (a.length + Array.from(a + b).reduce((sum, char) => sum + char.charCodeAt(0) - 'A'.charCodeAt(0) + 1, 0)) % 2 == 1;
            return randomTrueOrFalse ? 1 : -1
          })?.map((option: string, index: number) => {
            return ({ id: index.toString(), name: option, index: index })
          })}></SequenceVertical>}
          {attempt && attempt.answer && (attempt.correct == true || attempt.correct == false) && <div style={{ pointerEvents: 'none' }}><SequenceVertical setFieldValue={setFieldValue} itemsIn={attempt.answer.map((option: string, index: number) => {
            return ({ id: index.toString(), name: option, index: index })
          })}></SequenceVertical></div>}
          {attempt && (attempt.correct == false) && <div className={'row col-12 m-1 mt-2 justify-content-center align-bottom rounded animate__animated animate__bounceInUp border border-' + (attempt.correct == true ? 'success' : 'danger')} style={{ animationDelay: '0.75s' }}>
            <span className={'p-2 mb-2 bg-' + (attempt.correct == true ? 'success' : 'danger')}>Correct Order</span>
            {section?.options?.map((option: any) => { return <span><Latex>{option || ''}</Latex></span> })}
          </div>}
        </div>
      </div></>}
      {ErrorDisplay('answer', errors)}
      {attempt && section.explanation && section.explanation != '' && <div><div><strong>Explanation : </strong></div><Latex>{section.explanation || ''}</Latex></div>}
    </div>
  )
}