import axios from 'axios';
import { FC, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik'
import { ViewSection } from '../../modules/components/display/ViewSection';
import { DisplayLoader } from '../../utils/loader';
type ApiParams = { id: string; userId: string };
const Reports: FC = () => {
  const { id, userId } = useParams<ApiParams>();
  let [report, setReport] = useState<any>({});
  useEffect(() => {
    getReports();
  }, []);
  const getReports = () => {
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/get-reports/' + id + (userId ? ('/' + userId) : '')).then((reply) => {
      if (reply?.data) {
        if (reply.data.redirect) {
          window.location.pathname = reply.data.redirect;
        } else {
          setReport(reply.data);
        }
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }
  return (
    <div className='rounded border border-dark p-2 pb-0 shadow-lg m-5'>
      {userId && <h3 className='text-center m-4'>{report?.attempt?.name}</h3>}
      {(report?.challengeInstance?.type !== 'lesson' || userId) && <>
        {report?.stats && <div>
          <div className='col-4 p-3 d-inline-block text-center'>
            <div className='text-center bg-primary shadow-lg rounded p-4 pb-1 text-light'>
              <h4 className='d-inline-block text-light'>{report.attempt.totalPoints !== null ? report.attempt.totalPoints : '-'}</h4>/{report.stats.totalPoints}
              <h6 className='text-light text-light'>Points</h6>
            </div>
          </div>
          <div className='col-4 p-3 d-inline-block text-center'>
            <div className='text-center bg-success shadow-lg rounded p-4 pb-1'>
              <h4 className='text-light'>{report.stats.correct}</h4>
              <h6 className='text-light'>Correct</h6>
            </div>
          </div>
          <div className='col-4 p-3 d-inline-block text-center'>
            <div className='text-center bg-danger shadow-lg rounded p-4 pb-1'>
              <h4 className='text-light'>{report.stats.wrong}</h4>
              <h6 className='text-light'>Wrong</h6>
            </div>
          </div>
        </div>}
        {(report.attempt && !report.attempt.completed && report.challengeInstance.type !== 'lesson') && <h5 className='text-center text-danger m-5'>*Not submitted</h5>}

        {
          report.challenge?.sections?.map((section: string, index: number) => {
            return (
              <div className='m-2 shadow rounded p-4'>
                <div className='d-inline-block text-center bg-light shadow-lg rounded p-4 pb-1 mb-5'>
                  <b>{index + 1}</b>/{report.challenge.sections.length}<br />
                  <h6>Section</h6>
                </div>
                <div className={'d-inline-block float-end text-light text-center shadow-lg rounded p-4 pb-1 mb-5 ' + (report.attempt.sections[section]?.points > 0 ? 'bg-success' : (report.attempt.sections[section]?.answer != null ? 'bg-danger' : 'bg-info'))}>
                  <b>{report.attempt.sections[section]?.points ? report.attempt.sections[section]?.points : (report.attempt.sections[section]?.answer ? 0 : '-')}</b>/{report.sectionMap[section].points}<br />
                  <h6 className='text-light'>Points</h6>
                </div>
                {<Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
                  validationSchema={{}} initialValues={report.attempt.sections[section]} onSubmit={() => { }}>
                  {({ errors, touched, values, setFieldValue, setErrors }) => (
                    <Form>
                      {ViewSection(report.sectionMap[section], true, 1, errors, values, setFieldValue, report.attempt.sections[section] ? report.attempt.sections[section] : { ...report.sectionMap[section], ...{ correct: false } }, true)}
                    </Form>
                  )}
                </Formik>}
                <hr />
              </div>
            )
          })
        }
      </>}
    </div >
  )
}

export { Reports }
